import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'rsuite';
import { numberFormat, canManageCampaigns } from '../../app/utils';

const { Column, HeaderCell, Cell } = Table;

const CategoryClicks = ({ clicks }) => {

    const [data, setData] = useState([]);
    const currency = useSelector(state => state.base.website.currency);
    useEffect(() => {
        let d = [];
        let i = 1;
        let tclicks = 0;
        let tcost = 0;
        for (const click of clicks.data) {
            d.push({
                ...click,
                clicks: click.clicks ? click.clicks : "-",
                average: click.price ? (click.price / click.clicks) : "-",
                id: i
            })
            tcost += click.price;
            tclicks += click.clicks
            i++
        }
        setData(d)
    }, [clicks]);

    const PriceCell = ({ rowData, dataKey, ...props }) => {
        return (
            <Cell {...props}>

                {rowData.average != "-" ? <>
                    {numberFormat(rowData.average, 2)}<span className='currency'>{currency}</span>
                </> : ""}


            </Cell >
        );
    }

    const TotalCell = ({ rowData, dataKey, ...props }) => {
        return (
            <Cell {...props}>
                {rowData.price > 0 ? <>{numberFormat(rowData.price, 2)}<span className='currency'>{currency}</span> </> : "-"}
            </Cell>
        );
    }

    return (
        <>
            <Table
                autoHeight
                data={data}
                loading={clicks.page.loading}
                wordWrap="break-word"
                headerHeight={60}
            >
                <Column width={40}>
                    <HeaderCell></HeaderCell>
                    <Cell dataKey="id" />
                </Column>
                <Column width={200}>
                    <HeaderCell verticalAlign='middle'>Category</HeaderCell>
                    <Cell dataKey="name" />
                </Column>
                <Column width={100} align={"center"}>
                    <HeaderCell verticalAlign='middle'>Clicks</HeaderCell>
                    <Cell dataKey="clicks" />
                </Column>
                <Column width={100} align={"center"}>
                    <HeaderCell verticalAlign='middle'>Avg. CPC</HeaderCell>
                    <PriceCell dataKey="average" />
                </Column>
                <Column width={100} align={"center"}>
                    <HeaderCell verticalAlign='middle'>Costs</HeaderCell>
                    <TotalCell dataKey="price" />
                </Column>
            </Table>
        </>
    )
}

export default CategoryClicks;
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Button, Drawer, Badge, Placeholder } from 'rsuite';
import { getCampaignImpactDetailed, resetImpactDetailed } from '../campaigns-redux'

import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import CheckRoundIcon from '@rsuite/icons/CheckRound';
const { Column, HeaderCell, Cell } = Table;
const Impact = ({ formData }) => {

    const [openDetails, setOpenDetails] = useState(false);
    const [dataCategories, setDataCategories] = useState([]);
    const [dataBrandCategories, setDataBrandCategories] = useState([]);

    const impact = useSelector(state => state.campaigns.impact);
    const categories_selection = useSelector(state => state.campaigns.categories_selection);

    const dispatch = useDispatch();
    useEffect(() => {
        if (
            (formData.bid_type == "manual" && formData.bid) ||
            (formData.bid_type == "auto" && formData.bid && formData.max_bid && formData.increment)
        )
            dispatch(getCampaignImpactDetailed(formData))
        else
            dispatch(resetImpactDetailed())

    }, [formData])

    useEffect(() => {
        if (impact.categories && impact.categories.length > 0 && categories_selection && categories_selection.length > 0)
            setDataCategories(buildTree(impact.categories, categories_selection))
    }, [impact.categories, categories_selection])

    useEffect(() => {
        if (impact.brand_categories && impact.brand_categories.length > 0 && categories_selection && categories_selection.length > 0)
            setDataBrandCategories(buildTreeBrandCat(impact.brand_categories, categories_selection))
    }, [impact.brand_categories, categories_selection])

    const buildTree = (impactData, selectionData) => {
        const impactMap = new Map(impactData.map(item => [item.id, item]));
        const addedAsChildSet = new Set();

        function getChildren(parentId) {
            return selectionData
                .filter(item => (item.parent ? item.parent.id : null) === parentId)
                .map(item => {
                    const category = impactMap.get(item.id);
                    if (!category) return null;

                    addedAsChildSet.add(item.id);
                    const children = getChildren(item.id);
                    if (children.length) {
                        category.children = children;
                    }
                    return category;
                })
                .filter(item => item !== null);
        }

        // Find the categories in impactData that are also present in selectionData
        const validRoots = impactData.filter(cat => selectionData.some(sel => sel.id === cat.id));

        let tree = [];
        for (const root of validRoots) {
            if (!addedAsChildSet.has(root.id)) {
                const children = getChildren(root.id);
                if (children.length) {
                    root.children = children;
                }
                tree.push(root);
            }
        }

        return tree;
    }




    const buildTreeBrandCat = (impactData, selectionData) => {
        // Step 1: Flatten the selection data for easy parent lookup
        const parentMap = {};
        const flattenSelection = (data) => {
            if (!data.parent) return;
            parentMap[data.id] = data.parent.id;
            flattenSelection(data.parent);
        };

        selectionData.forEach(flattenSelection);

        // Step 2: Group impact data by brand and associate with parent category
        const groupedByBrand = {};
        impactData.forEach(item => {
            const [brand_id, cat_id] = item.id.split('-').map(Number);
            if (!groupedByBrand[brand_id]) {
                groupedByBrand[brand_id] = [];
            }
            groupedByBrand[brand_id].push({ ...item, brand_id, cat_id });
        });

        // Function to recursively add children to an item
        const addChild = (item, data) => {
            item.children = data.filter(i => parentMap[i.cat_id] === item.cat_id).map(childItem => addChild(childItem, data));
            if (item.children.length === 0) delete item.children;
            return item;
        };

        // Step 3: Construct the desired output
        const output = [];
        Object.values(groupedByBrand).forEach(brandItems => {
            // Identify root items (those whose category parent isn't in the current brand group)
            const roots = brandItems.filter(item => !brandItems.some(i => i.cat_id === parentMap[item.cat_id]));
            roots.forEach(root => {
                output.push(addChild(root, brandItems));
            });
        });

        return output;
    };


    return (
        <>
            {!impact.loading ? <>
                {"Targeted products:"} <span className='font-weight-bold'>{impact.total_targeted}</span>

                <Table
                    className='pt-2'
                    data={impact.overall}
                >
                    <Column width={70} align="center" fixed>
                        <HeaderCell>Position</HeaderCell>
                        <Cell className='text-center' dataKey="position">
                            {rowData => (
                                <>
                                    {rowData.position == 1 ?
                                        <Badge color="green" content="1" className="mr-2  font-weight-bold" />
                                        : rowData.position == 2 ?
                                            <Badge color="yellow" content="2" className="mr-2  font-weight-bold" />
                                            :
                                            <Badge color="orange" content="3" className="mr-2 font-weight-bold" />
                                    }
                                </>
                            )}
                        </Cell>



                    </Column>
                    <Column width={120} align="center" >
                        <HeaderCell>% of targeted</HeaderCell>
                        <Cell dataKey="percentage_total">
                            {rowData => (
                                <>{Math.round((rowData.percentage_total * 100) * 100) / 100} {"%"}</>
                            )}
                        </Cell>
                    </Column>
                    <Column width={80} align="center">
                        <HeaderCell>Products</HeaderCell>
                        <Cell dataKey="products" />
                    </Column>
                </Table>
                <div className='text-right'>
                    <Button onClick={() => setOpenDetails(true)}>Impact details</Button>
                </div>


                <Drawer open={openDetails} onClose={() => setOpenDetails(false)} size={"lg"}>
                    <Drawer.Header>
                        <Drawer.Title>Impact details (by target group)</Drawer.Title>


                    </Drawer.Header>
                    <Drawer.Body>
                        <div className='pb-4'>{"Targeted products:"} <span className='font-weight-bold'>{impact.total_targeted}</span></div>

                        {formData.categories && formData.categories.length > 0 && formData.targeting_type == "category" ?
                            <Table
                                className='pt-2'
                                data={dataCategories}
                                autoHeight
                                isTree
                                rowKey="id"
                                renderTreeToggle={(icon, rowData) => {
                                    if (rowData.children && rowData.children.length === 0) {
                                        return <SpinnerIcon spin />;
                                    }
                                    return icon;
                                }}
                            >
                                <Column width={300} fixed>
                                    <HeaderCell>Category</HeaderCell>
                                    <Cell dataKey="name" />
                                </Column>
                                <Column width={150} >
                                    <HeaderCell>
                                        <Badge color="green" content="1" className="mr-2  font-weight-bold" />
                                    </HeaderCell>
                                    <Cell>
                                        {rowData => (
                                            <>
                                                {rowData.pos1_products ?
                                                    <>
                                                        <strong>{rowData.pos1_products}</strong>
                                                        <small>{" (" + Math.round((rowData.pos1_percentage_total * 100) * 100) / 100 + "%)"} </small>
                                                    </>
                                                    : "-"}
                                            </>
                                        )}
                                    </Cell>
                                </Column>
                                <Column width={150}  >
                                    <HeaderCell>
                                        <Badge color="yellow" content="2" className="mr-2  font-weight-bold" />
                                    </HeaderCell>
                                    <Cell>
                                        {rowData => (
                                            <>
                                                {rowData.pos2_products ?
                                                    <>
                                                        <strong>{rowData.pos2_products}</strong>
                                                        <small>{" (" + Math.round((rowData.pos2_percentage_total * 100) * 100) / 100 + "%)"}</small>
                                                    </>
                                                    : "-"}
                                            </>
                                        )}
                                    </Cell>
                                </Column>
                                <Column width={150}  >
                                    <HeaderCell>
                                        <Badge color="orange" content="3" className="mr-2 font-weight-bold" />
                                    </HeaderCell>
                                    <Cell>
                                        {rowData => (
                                            <>
                                                {rowData.pos3_products ?
                                                    <>
                                                        <strong>{rowData.pos3_products}</strong>
                                                        <small>{" (" + Math.round((rowData.pos3_percentage_total * 100) * 100) / 100 + "%)"}</small>
                                                    </>
                                                    : "-"}
                                            </>
                                        )}
                                    </Cell>
                                </Column>
                            </Table>

                            : ""}


                        {
                            formData.categories &&
                                formData.categories.length > 0 &&
                                formData.brands &&
                                formData.brands.length > 0 &&
                                formData.targeting_type == "category+brand"

                                ?
                                <Table
                                    className='pt-2'
                                    data={dataBrandCategories}
                                    autoHeight
                                    isTree
                                    rowKey="id"
                                    //defaultExpandAllRows
                                    renderTreeToggle={(icon, rowData) => {
                                        if (rowData.children && rowData.children.length === 0) {
                                            return <SpinnerIcon spin />;
                                        }
                                        return icon;
                                    }}
                                >
                                    <Column width={300} fixed>
                                        <HeaderCell>Brand + Category</HeaderCell>
                                        <Cell dataKey="name" />
                                    </Column>
                                    <Column width={150}>
                                        <HeaderCell><Badge color="green" content="1" className="mr-2  font-weight-bold" /></HeaderCell>
                                        <Cell>
                                            {rowData => (
                                                <>
                                                    {rowData.pos1_products ?
                                                        <>
                                                            <strong>{rowData.pos1_products}</strong>
                                                            <small>{" (" + Math.round((rowData.pos1_percentage_total * 100) * 100) / 100 + "%)"} </small>
                                                        </>
                                                        : "-"}
                                                </>
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column width={150}>
                                        <HeaderCell><Badge color="yellow" content="2" className="mr-2  font-weight-bold" /></HeaderCell>
                                        <Cell>
                                            {rowData => (
                                                <>
                                                    {rowData.pos2_products ?
                                                        <>
                                                            <strong>{rowData.pos2_products}</strong>
                                                            <small>{" (" + Math.round((rowData.pos2_percentage_total * 100) * 100) / 100 + "%)"}</small>
                                                        </>
                                                        : "-"}
                                                </>
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column width={150}>
                                        <HeaderCell><Badge color="orange" content="3" className="mr-2 font-weight-bold" /></HeaderCell>
                                        <Cell>
                                            {rowData => (
                                                <>
                                                    {rowData.pos3_products ?
                                                        <>
                                                            <strong>{rowData.pos3_products}</strong>
                                                            <small>{" (" + Math.round((rowData.pos3_percentage_total * 100) * 100) / 100 + "%)"}</small>
                                                        </>
                                                        : "-"}
                                                </>
                                            )}
                                        </Cell>
                                    </Column>
                                </Table>
                                : ""}

                        {formData.brands && formData.brands.length > 0 && formData.targeting_type == "brand" ?
                            <Table
                                className='pt-2'
                                data={impact.brands}
                                autoHeight
                            >
                                <Column width={300} fixed>
                                    <HeaderCell>Brand</HeaderCell>
                                    <Cell dataKey="name" />
                                </Column>
                                <Column width={150}>
                                    <HeaderCell>
                                        <Badge color="green" content="1" className="mr-2  font-weight-bold" />
                                    </HeaderCell>
                                    <Cell>
                                        {rowData => (
                                            <>
                                                {rowData.pos1_products ?
                                                    <>
                                                        <strong>{rowData.pos1_products}</strong>
                                                        <small>{" (" + Math.round((rowData.pos1_percentage_total * 100) * 100) / 100 + "%)"}</small>
                                                    </>
                                                    : "-"}
                                            </>
                                        )}
                                    </Cell>
                                </Column>
                                <Column width={150}>
                                    <HeaderCell>
                                        <Badge color="yellow" content="2" className="mr-2  font-weight-bold" />
                                    </HeaderCell>
                                    <Cell>
                                        {rowData => (
                                            <>
                                                {rowData.pos2_products ?
                                                    <>
                                                        <strong>{rowData.pos2_products}</strong>
                                                        <small>{" (" + Math.round((rowData.pos2_percentage_total * 100) * 100) / 100 + "%)"}</small>
                                                    </>
                                                    : "-"}
                                            </>
                                        )}
                                    </Cell>
                                </Column>
                                <Column width={150}>
                                    <HeaderCell>
                                        <Badge color="orange" content="3" className="mr-2 font-weight-bold" />
                                    </HeaderCell>
                                    <Cell>
                                        {rowData => (
                                            <>
                                                {rowData.pos3_products ?
                                                    <>
                                                        <strong>{rowData.pos3_products}</strong>
                                                        <small>{" (" + Math.round((rowData.pos3_percentage_total * 100) * 100) / 100 + "%)"} </small>
                                                    </>
                                                    : "-"}
                                            </>
                                        )}
                                    </Cell>
                                </Column>
                            </Table>

                            : ""}


                        {formData.product_variations && formData.product_variations.length > 0 && formData.targeting_type == "product" ?
                            <Table
                                className='pt-2'
                                data={impact.products}
                                autoHeight
                            >
                                <Column width={300} fixed>
                                    <HeaderCell>Product</HeaderCell>
                                    <Cell dataKey="name" />
                                </Column>
                                <Column width={150}>
                                    <HeaderCell>
                                        <Badge color="green" content="1" className="mr-2  font-weight-bold" />
                                    </HeaderCell>
                                    <Cell>
                                        {rowData => (rowData.pos1_products ? <CheckRoundIcon /> : "-")}
                                    </Cell>
                                </Column>
                                <Column width={150}>
                                    <HeaderCell>
                                        <Badge color="yellow" content="2" className="mr-2  font-weight-bold" />
                                    </HeaderCell>
                                    <Cell>
                                        {rowData => (rowData.pos2_products ? <CheckRoundIcon /> : "-")}
                                    </Cell>
                                </Column>
                                <Column width={150}>
                                    <HeaderCell>
                                        <Badge color="orange" content="3" className="mr-2 font-weight-bold" />
                                    </HeaderCell>
                                    <Cell>
                                        {rowData => (rowData.pos3_products ? <CheckRoundIcon /> : "-")}
                                    </Cell>
                                </Column>
                            </Table>

                            : ""}
                    </Drawer.Body>
                </Drawer>
            </> : <Placeholder.Paragraph style={{ marginTop: 30 }} active rows={6} />}
        </>
    )
}

export default Impact;
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    MultiCascader, Divider, Tag, FlexboxGrid
} from 'rsuite';
import { presistChanges } from '../campaigns-redux';
import { convertBrandsForFilter, getSelectionValues, genObjectsFromIds } from '../../../app/utils';


const BrandSelection = ({ ftype, brands, formData, selected_brand }) => {
    const dispatch = useDispatch();
    const [brandsData, setBrandsData] = useState([]);

    const [selectedValues, setSelectedValues] = useState(getSelectionValues(formData.brands));


    useEffect(() => {
        if (brands && brands.length > 0) {
            setBrandsData(convertBrandsForFilter(brands))
        }
    }, [brands]);


    const handleSelectionChange = (values) => {
        setSelectedValues(values);
        dispatch(presistChanges({ ...formData, brands: genObjectsFromIds(values, brandsData) }, ftype));
    };

    const handleTagClose = (brand) => {
        const newBrands = formData.brands.filter((item) => item !== brand);
        const newValues = getSelectionValues(newBrands);
        setSelectedValues(newValues);
        dispatch(presistChanges({ ...formData, brands: newBrands }, ftype));
    };

    return (
        <>

            <MultiCascader
                style={{ width: 500 }}
                menuWidth={300}
                menuHeight={300}
                data={brandsData}
                valueKey={"id"}
                placeholder={"Show products of certain brand"}
                //block
                value={selectedValues}
                onChange={handleSelectionChange}
                className='pt-3'
            />


            {formData.brands &&
                formData.brands.length > 0 ?
                <div className='pt-3 pb-2'>Selected brands:</div>
                : ""
            }

            {formData.brands.map((brand, index) => (
                <Tag
                    className='my-1 mx-2'
                    closable
                    key={index}
                    onClose={() => handleTagClose(brand)}
                    color="primary"
                >{brand.name}</Tag>
            ))}

        </>

    );
}


export default BrandSelection;
import React, { useEffect } from 'react';
import {
    Notification,
    useToaster
} from 'rsuite';

import { useSelector, useDispatch } from "react-redux";
import { removeNotification } from "./base-redux";

const Notifications = () => {
    const notification = useSelector(state => state.base.notification);
    const toaster = useToaster();
    const dispatch = useDispatch();
    useEffect(() => {
        if (notification.message) {
            toaster.push(message, { placement: 'topCenter' });
            dispatch(removeNotification());
        }
    }, [notification]);


    const message = (
        <Notification
            type={notification.type ? notification.type : "info"}
            header={
                notification.type && notification.type === "info" ? "Info" :
                    notification.type && notification.type === "error" ? "Greška" :
                        notification.type && notification.type === "warning" ? "Upozorenje" :
                            notification.type && notification.type === "success" ? "Uspešno" :
                                "Info"
            }
            closable
            duration={notification.duration ? notification.duration : 5000}
        >
            {notification.message}
        </Notification >
    );
}




export default Notifications;
import ptekaApi from '../../app/services';
import { showError } from '../_base/base-redux'

//CONSTANTS
export const LOADING_IMPRESSIONS_CLICKS = 'LOADING_IMPRESSIONS_CLICKS'
export const GET_IMPRESSIONS_CLICKS = 'GET_IMPRESSIONS_CLICKS'

export const LOADING_IMPRESSIONS_CLICKS_CAMPAIGNS = 'LOADING_IMPRESSIONS_CLICKS_CAMPAIGNS'
export const GET_IMPRESSIONS_CLICKS_CAMPAIGNS = 'GET_IMPRESSIONS_CLICKS_CAMPAIGNS'

export const LOADING_CATEGORY_CLICKS = 'LOADING_CATEGORY_CLICKS'
export const GET_CATEGORY_CLICKS = 'GET_CATEGORY_CLICKS'


//INITIAL STATE
const INITIAL_STATE = {
    impression_clicks: {
        impressions: 0,
        total_clicks: 0,
        total_price: 0,
        clicks: [],
        loading: false
    },
    impression_clicks_campaigns: {
        cpc:{
            impressions: 0,
            total_clicks: 0,
            total_price: 0,
            total_campaign_price: 0,
            clicks: [],
        },
        cpm:{
            impressions: 0,
            total_campaign_price: 0,
            total_clicks:0,
            clicks: [],
        },
        loading: false
    },
    category_clicks: {
        data: [],
        page: {
            number: 0,
            size: 0,
            total_found: 0,
            loading: false
        },
    }
};


//REDUCERS
export const dashbaordReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case LOADING_IMPRESSIONS_CLICKS:
            return {
                ...state,
                impression_clicks: {
                    ...state.impression_clicks,
                    loading: action.payload
                }
            };

        case GET_IMPRESSIONS_CLICKS:
            return {
                ...state,
                impression_clicks: {
                    ...state.impression_clicks,
                    ...action.payload.data
                }
            };

        case LOADING_IMPRESSIONS_CLICKS_CAMPAIGNS:
            return {
                ...state,
                impression_clicks_campaigns: {
                    ...state.impression_clicks_campaigns,
                    loading: action.payload
                }
            };

        case GET_IMPRESSIONS_CLICKS_CAMPAIGNS:
            return {
                ...state,
                impression_clicks_campaigns: {
                    ...state.impression_clicks_campaigns,
                    ...action.payload.data
                }
            };

        case GET_CATEGORY_CLICKS:
            return {
                ...state,
                category_clicks: {
                    ...state.category_clicks,
                    data: action.payload.data,
                    page: {
                        ...state.page,
                        number: action.payload.page ? action.payload.page : 0,
                        size: action.payload.page_size ? action.payload.page_size : 0,
                        total_found: action.payload.found ? action.payload.found : 0
                    }
                }
            };
        case LOADING_CATEGORY_CLICKS:
            return {
                ...state,
                category_clicks: {
                    ...state.category_clicks,
                    page: {
                        ...state.page,
                        loading: action.payload
                    }
                }
            };

        default:
            return state;
    }
}


//ACTIONS
export const getImpressionsAndClicks = (date_from, date_to) => (dispatch, getState) => {
    dispatch({ type: LOADING_IMPRESSIONS_CLICKS, payload: true })
    const { selected_website } = getState().base;
    const { selected_account } = getState().base;
    const { selected_brand } = getState().base;
    const { selected_shop } = getState().base;

    if (selected_account)
        ptekaApi.get('/reports/clicks-and-impressions', {
            params: {
                date_from: date_from ? date_from : null,
                date_to: date_to ? date_to : null,
                account_id: selected_account,
                websites: selected_website ? [selected_website] : null,
                shops: selected_shop ? [selected_shop] : null,
                brands: selected_brand ? [selected_brand] : null
            }
        })
            .then(res => {
                dispatch({ type: LOADING_IMPRESSIONS_CLICKS, payload: false })
                dispatch({ type: GET_IMPRESSIONS_CLICKS, payload: res.data })
            })
            .catch(err => {
                dispatch({ type: LOADING_IMPRESSIONS_CLICKS, payload: false })
                dispatch(showError(err))
            });
};


export const getCategoryClicks = (date_from, date_to) => (dispatch, getState) => {
    dispatch({ type: LOADING_CATEGORY_CLICKS, payload: true })
    const { selected_website } = getState().base;
    const { selected_account } = getState().base;
    const { selected_brand } = getState().base;
    const { selected_shop } = getState().base;

    ptekaApi.get('/reports/clicks-per-redirect-cat', {
        params: {
            date_from: date_from ? date_from : null,
            date_to: date_to ? date_to : null,
            account_id: selected_account,
            websites: selected_website ? [selected_website] : null,
            shops: selected_shop ? [selected_shop] : null,
            brands: selected_brand ? [selected_brand] : null
        }
    })
        .then(res => {
            dispatch({ type: LOADING_CATEGORY_CLICKS, payload: false })
            dispatch({ type: GET_CATEGORY_CLICKS, payload: res.data })
        })
        .catch(err => {
            dispatch({ type: LOADING_CATEGORY_CLICKS, payload: false })
            dispatch(showError(err))
        });
};


export const getImpressionsAndClicksCampaigns = (date_from, date_to) => (dispatch, getState) => {
    dispatch({ type: LOADING_IMPRESSIONS_CLICKS_CAMPAIGNS, payload: true })
    const { selected_website } = getState().base;
    const { selected_account } = getState().base;
    const { selected_brand } = getState().base;
    const { selected_shop } = getState().base;

    if (selected_account)
        ptekaApi.get('/reports/clicks-and-impressions-campaigns', {
            params: {
                date_from: date_from ? date_from : null,
                date_to: date_to ? date_to : null,
                account_id: selected_account,
                websites: selected_website ? [selected_website] : null,
                shops: selected_shop ? [selected_shop] : null,
                brands: selected_brand ? [selected_brand] : null
            }
        })
            .then(res => {
                dispatch({ type: LOADING_IMPRESSIONS_CLICKS_CAMPAIGNS, payload: false })
                dispatch({ type: GET_IMPRESSIONS_CLICKS_CAMPAIGNS, payload: res.data })
            })
            .catch(err => {
                dispatch({ type: LOADING_IMPRESSIONS_CLICKS_CAMPAIGNS, payload: false })
                dispatch(showError(err))
            });
};

import React, { useState, useEffect } from 'react';
import { AreaChart, Area, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment-timezone';
import styles from './click-chart.module.scss';
import { cloneDeep } from 'lodash';
const ClickChart = ({ clicks, start, end, currency }) => {
    const [data, setData] = useState([]);
    //const [view, setView] = useState("daily");
    const view = end.diff(start, 'days') <= 60 ? "daily" : "monthly";


    useEffect(() => {
        let formated_clicks = [];
        let start_date = cloneDeep(start);
        let end_date = cloneDeep(end);
        if (clicks && clicks.length > 0) {
            //daily view
            if (end_date.diff(start_date, 'days') <= 60) {
                while (start_date.isBefore(end_date) && start_date.isBefore(moment())) {
                    let generate_zero = true;
                    for (let click of clicks) {
                        if (click.date == start_date.format('YYYY-MM-DD')) {
                            generate_zero = false;
                            formated_clicks.push({
                                date: moment(click.date, "YYYY-MM-DD").format("DD.MM.YYYY"),
                                formated: moment(click.date, "YYYY-MM-DD").format("MMM DD"),
                                clicks: click.click_count,
                                price: click.click_price_total
                            })
                        }
                    }
                    if (generate_zero)
                        formated_clicks.push({
                            date: start_date.format("DD.MM.YYYY"),
                            formated: start_date.format("MMM DD"),
                            clicks: 0,
                            price: 0
                        })
                    start_date.add(1, 'day');
                }
            } else {  //monthly view
                while (start_date.isBefore(end_date) && start_date.isBefore(moment())) {
                    let total_clicks = 0;
                    let total_costs = 0;
                    for (let click of clicks) {
                        if (start_date.isSame(moment(click.date), 'month')) {
                            total_clicks += click.click_count
                            total_costs += click.click_price_total
                        }
                    }
                    formated_clicks.push({
                        date: start_date.format("DD.MM.YYYY"),
                        formated: start_date.format("MMM YY"),
                        clicks: total_clicks,
                        price: total_costs
                    })
                    start_date.add(1, 'month');
                }
            }
        }
        setData(formated_clicks)
    }, [clicks]);
    const CustomTooltip = ({ payload }) => {
        return (
            <div className={styles.custom_tooltip}>
                {view === "daily" ?
                    <p className={styles.label}>
                        {payload && payload[0] && payload[0].payload.date ? moment(payload[0].payload.date, "DD.MM.YYYY").format("DD.MM.YYYY") : ""}<br />
                        <span className={styles.day}>{payload && payload[0] && payload[0].payload.date ? moment(payload[0].payload.date, "DD.MM.YYYY").format("dddd") : ""}</span><br />
                    </p>
                    :
                    <p className={styles.label}>
                        {payload && payload[0] && payload[0].payload.date ? moment(payload[0].payload.date, "DD.MM.YYYY").format("MMM YYYY") : ""}<br />
                    </p>}
                {payload ? payload.map((entry, i) => (
                    <>
                        <p key={i} className={styles.clicks}>{entry.value} <span> clicks</span></p>
                        <p key={"k"+i} className={styles.price}>{entry.payload && entry.payload.price ? Math.round(entry.payload.price * 100) / 100 : 0} <span>{currency}</span></p>
                    </>
                )) : ""}
            </div>
        );
    };
    return (
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: -20,
                    bottom: 5,
                }}
            >

                <XAxis dataKey="formated" angle={-45} height={80} dy={20} />
                <YAxis tick={{ step: 1 }} />
                <Tooltip content={<CustomTooltip />} />

                <Area type="monotone" dataKey="clicks" stroke="#822165" fill="rgba(130,33,101,0.1)" />
            </AreaChart>
        </ResponsiveContainer>
    )
}

export default ClickChart;
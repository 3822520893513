import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Notifications from './notifications'
import NavContainer from './navigation'
import { getWebsiteDetails } from './base-redux'

const LayoutPublic = ({ children }) => {
    return (
        <>
            <Notifications />
            {children}
        </>
    );
}

const LayoutPrivate = ({ children }) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.base.user);
    useEffect(() => {
        if (user)
            dispatch(getWebsiteDetails(user));
    }, [dispatch, user]);
    return (
        <>
            <Notifications />
            <NavContainer>
                {children}
            </NavContainer >
        </>
    );
}

export { LayoutPublic, LayoutPrivate };
import ptekaApi from '../../app/services';
import { showError } from '../_base/base-redux'

//CONSTANTS
export const LOADING_LOGS = 'LOADING_LOGS'
export const GET_LOGS = 'GET_LOGS'


//INITIAL STATE
const INITIAL_STATE = {
    data: [],
    page: {
        number: 0,
        size: 0,
        total_found: 0,
        loading: false,
        //filters: null,
        //sort: null,
        //sort_by: null
    },
};


//REDUCERS
export const logsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_LOGS:
            return {
                ...state,
                data: action.payload.data,
                page: {
                    ...state.page,
                    number: action.payload.page ? action.payload.page : 0,
                    size: action.payload.page_size ? action.payload.page_size : 0,
                    total_found: action.payload.found ? action.payload.found : 0
                }
            };
        case LOADING_LOGS:
            return {
                ...state,
                page: {
                    ...state.page,
                    loading: action.payload
                }
            };

        default:
            return state;
    }
}


//ACTIONS
export const getAll = (page = null) => (dispatch, getState) => {
    dispatch({ type: LOADING_LOGS, payload: true })
    const { selected_website } = getState().base;
    const { selected_account } = getState().base;
    const { selected_brand } = getState().base;
    const { selected_shop } = getState().base;


    ptekaApi.get('/redirects', {
        params: {
            page: page && page.number ? page.number : 0,
            account_id: selected_account,
            websites: selected_website ? [selected_website] : null,
            shops: selected_shop ? [selected_shop] : null,
            brands: selected_brand ? [selected_brand] : null
        }
    })
        .then(res => {
            dispatch({ type: LOADING_LOGS, payload: false })
            dispatch({ type: GET_LOGS, payload: res.data })
        })
        .catch(err => {
            dispatch({ type: LOADING_LOGS, payload: false })
            dispatch(showError(err))
        });
};
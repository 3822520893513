import ptekaApi from '../../app/services';
import { getUserProfile, showError, showSuccess, BASE_LOADING } from "../_base/base-redux";

export const loginUser = (credentials, history, token) => dispatch => {
    dispatch({ type: BASE_LOADING })
    ptekaApi
        .post('/user/login', { ...credentials, captcha_token: token })
        .then(res => {
            if (res.data.message) {
                dispatch(showError({ message: res.data.message }))
            } else {
                dispatch(getUserProfile(history));
            }
            dispatch({ type: BASE_LOADING })
        })
        //.catch(showError);
        .catch(err => { dispatch({ type: BASE_LOADING }); dispatch(showError(err)) });
};

export const forgotPass = (credentials, history, token) => dispatch => {
    ptekaApi
        .post('/user/password/forgot', {
            ...credentials,
            captcha_token: token,
            base_uri: process.env.REACT_APP_URL
        })
        .then(res => {
            dispatch(showSuccess(res.data.message, 10000))
            history.push("/");
        })
        .catch(err => { dispatch({ type: BASE_LOADING }); dispatch(showError(err)) });
};

export const resetPass = (credentials, history) => dispatch => {
    ptekaApi
        .post('/user/reset', credentials)
        .then(res => {
            dispatch(showSuccess(res.data.message))
            history.push("/");
        })
        .catch(err => { dispatch({ type: BASE_LOADING }); dispatch(showError(err)) });
};




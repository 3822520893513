import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'rsuite';
import { numberFormat } from '../../app/utils';

const { Column, HeaderCell, Cell } = Table;

const CategoryClicks = ({ start, end, clicks }) => {
    const [data, setData] = useState([]);
    const currency = useSelector(state => state.base.website.currency);

    useEffect(() => {
        let d = [];
        let i = 1;
        for (const click of clicks.data) {
            d.push({
                ...click,
                clicks_campaigns: click.clicks_campaigns ? click.clicks_campaigns : "-",
                average_campaigns: click.price_campaigns ? (click.price_campaigns / click.clicks_campaigns) : "-",
                total_price: click.price_campaigns > 0 || click.bidding_price_campaigns > 0 ? (click.price_campaigns + click.bidding_price_campaigns) : "-",
                id: i
            })
            i++
        }
        setData(d)
    }, [clicks]);

    return (
        <>
            <Table
                autoHeight
                data={data}
                loading={clicks.page.loading}
                wordWrap="break-word"
                headerHeight={60}
            >
                <Column width={40}>
                    <HeaderCell></HeaderCell>
                    <Cell dataKey="id" />
                </Column>
                <Column width={200}>
                    <HeaderCell verticalAlign='middle'>Category</HeaderCell>
                    <Cell dataKey="name" />
                </Column>
                <Column width={100} align={"center"}>
                    <HeaderCell verticalAlign='middle'>Clicks</HeaderCell>
                    <Cell dataKey="clicks_campaigns" />
                </Column>
                <Column width={100} align={"center"}>
                    <HeaderCell verticalAlign='middle'>Avg. CPC</HeaderCell>
                    <Cell>
                        {rowData => (
                            <>
                                {rowData.average_campaigns != "-" ? <>
                                    {numberFormat(rowData.average_campaigns, 2)}<span className='currency'>{currency}</span>
                                </> : ""}
                            </>
                        )}
                    </Cell>
                </Column>
                <Column width={100} align={"center"}>
                    <HeaderCell verticalAlign='middle'>Campaign Costs</HeaderCell>
                    <Cell>
                        {rowData => (
                            <>
                                {rowData.bidding_price_campaigns > 0 ? <>{numberFormat(rowData.bidding_price_campaigns, 2)}<span className='currency'>{currency}</span> </> : "-"}
                            </>
                        )}
                    </Cell>
                </Column>
                <Column width={90} align={"center"}>
                    <HeaderCell verticalAlign='middle'>Organic Costs</HeaderCell>
                    <Cell>
                        {rowData => (
                            <>
                                {rowData.price_campaigns > 0 ? <>{numberFormat(rowData.price_campaigns, 2)}<span className='currency'>{currency}</span> </> : "-"}
                            </>
                        )}
                    </Cell>
                </Column>
                <Column width={100} align={"center"}>
                    <HeaderCell verticalAlign='middle'>Total Costs</HeaderCell>
                    <Cell>
                        {rowData => (
                            <>
                                {rowData.total_price > 0 ? <>{numberFormat(rowData.total_price, 2)}<span className='currency'>{currency}</span> </> : "-"}
                            </>
                        )}
                    </Cell>
                </Column>

            </Table>
        </>
    )
}

export default CategoryClicks;
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    Content, Panel, Button,
    ButtonToolbar, Steps, Form, Schema,
    RadioTile, RadioTileGroup, Divider,
    Message
} from 'rsuite';

import CreativeIcon from '@rsuite/icons/Creative';

import { presistChanges, getInitialSelection, insertCampaign, resetCampaign } from './campaigns-redux';


import styles from './campaigns.module.scss';

import FileUploadIcon from '@rsuite/icons/FileUpload';
import ListIcon from '@rsuite/icons/List';
import ScatterIcon from '@rsuite/icons/Scatter';
import AbTestIcon from '@rsuite/icons/AbTest';

import AdminIcon from '@rsuite/icons/Admin';
import TaskIcon from '@rsuite/icons/Task';

import CategorySelection from './common/category_selection'
import BrandSelection from './common/brand_selection'
import ProductSelection from './common/product_selection'
import { NumberField, StartDateField, EndDateField } from './common/form_fields'
import Impact from './common/impact-cpc'
import { useHistory } from 'react-router-dom';
import { formatErrors } from '../../app/utils';


const CampaignsPPC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const ftype = "new"
    const campaigns = useSelector(state => state.campaigns.data);
    const formData = useSelector(state => state.campaigns.new);
    const loading = useSelector(state => state.campaigns.loading);

    const categories = useSelector(state => state.campaigns.categories_selection);
    const brands = useSelector(state => state.campaigns.brands_selection);
    const products = useSelector(state => state.campaigns.products_selection);
    const selected_shop = useSelector(state => state.base.selected_shop);
    const user = useSelector(state => state.base.user);
    const website_id = useSelector(state => state.base.selected_website);

    const currency = useSelector(state => state.base.website.currency);

    const [initialLoad, setInitialLoad] = useState(true);

    useEffect(() => {
        if (initialLoad && website_id) {
            dispatch(getInitialSelection())
            setInitialLoad(false)
            dispatch(presistChanges({ ...formData, campaign_type: "cpc" }, ftype))
        }
    }, [website_id])

    const [steps, setSteps] = useState({
        step1: "wait",
        step2: "wait",
        step3: "wait",
        step4: "wait",
    });


    // useEffect(() => {
    //     dispatch(getCampaigns());
    // }, []);

    const modelManualBid = Schema.Model({
        name: Schema.Types.StringType().rangeLength(5, 255, 'The number of characters must be between 5 and 255').isRequired('Campaign name is required.'),
        bid: Schema.Types.NumberType().min(0.005, "Min bid is 0.005" + currency).max(1, "Max bid is 1" + currency)
            .addRule((value, data) => {
                if (data.bid_type === "auto" && parseFloat(data.bid) > parseFloat(data.max_bid))
                    return false;
                return true;
            }, 'Bid is higher than Max bid').isRequired('Missing bid amount'),
        budget: Schema.Types.NumberType()
            .addRule((value, data) => {
                if (data.daily_budget && parseFloat(data.daily_budget) > parseFloat(value))
                    return false;
                return true;
            }, 'Total budget should be higher than daily budget'),
        daily_budget: Schema.Types.NumberType()
            .addRule((value, data) => {
                if (data.daily_budget && parseFloat(data.daily_budget) > parseFloat(value))
                    return false;
                return true;
            }, 'Daily budget should be lower than total budget'),
    });

    const modelAutoBid = Schema.Model({
        name: Schema.Types.StringType().rangeLength(5, 255, 'The number of characters must be between 5 and 255').isRequired('Campaign name  is required.'),
        bid: Schema.Types.NumberType()
            .addRule((value, data) => {
                if (data.bid_type === "auto" && parseFloat(data.bid) > parseFloat(data.max_bid))
                    return false;
                return true;
            }, 'Bid is higher than Max bid').isRequired('Missing bid amount'),
        max_bid: Schema.Types.NumberType()
            .addRule((value, data) => {
                if (data.bid_type === "auto" && parseFloat(data.bid) > parseFloat(data.max_bid))
                    return false;
                return true;
            }, 'Max bid is lower than regular bid').isRequired('Missing max bid amount'),
        increment: Schema.Types.NumberType().isRequired('Missing increment value'),
        budget: Schema.Types.NumberType()
            .addRule((value, data) => {
                if (data.daily_budget && parseFloat(data.daily_budget) > parseFloat(value))
                    return false;
                return true;
            }, 'Total budget should be higher than daily budget'),
        daily_budget: Schema.Types.NumberType()
            .addRule((value, data) => {
                if (data.daily_budget && parseFloat(data.daily_budget) > parseFloat(value))
                    return false;
                return true;
            }, 'Daily budget should be lower than total budget'),
    });


    const step1Condition = (formData) => { return formData && formData.name && formData.name.length > 4 }
    const step2Condition = (formData) => {
        return formData &&
            formData.targeting_type &&
            formData.targeting_type != "" &&
            (
                (formData.targeting_type == "category" && formData.categories && formData.categories.length > 0) ||
                (formData.targeting_type == "category+brand" && formData.categories && formData.categories.length > 0 && formData.brands && formData.brands.length > 0) ||
                (formData.targeting_type == "brand" && formData.brands && formData.brands.length > 0) ||
                (formData.targeting_type == "product" && formData.product_variations && formData.product_variations.length > 0))
    }
    const step3Condition = (formData) => { return formData && formData.bid && formData.bid > 0 }
    //const step4Condition = (formData) => { return false }

    useEffect(() => {

        let sobject = {
            ...steps
        }

        // set first which step should be done
        if (
            step1Condition(formData) &&
            step2Condition(formData) &&
            step3Condition(formData)
        )
            sobject = { ...sobject, step4: "process" }
        else if (
            step1Condition(formData) &&
            step2Condition(formData)
        )
            sobject = { ...sobject, step3: "process" }
        else if (
            step1Condition(formData)
        )
            sobject = { ...sobject, step2: "process" }
        else
            sobject = { ...sobject, step1: "process" }


        //handle step1
        if (step1Condition(formData))
            sobject = { ...sobject, step1: "finish" }
        else if (sobject.step1 != "process")
            sobject = { ...sobject, step1: "process" }


        //handle step2
        if (step2Condition(formData))
            sobject = { ...sobject, step2: "finish" }
        else if (step1Condition(formData) && sobject.step2 != "process")
            sobject = { ...sobject, step2: "process" }
        else if (sobject.step1 == "process")
            sobject = { ...sobject, step2: "wait" }


        //handle step3
        if (step3Condition(formData))
            sobject = { ...sobject, step3: "finish" }
        else if (step1Condition(formData) && step2Condition(formData) && sobject.step3 != "process")
            sobject = { ...sobject, step3: "process" }
        else if (sobject.step1 == "process" || sobject.step2 == "process")
            sobject = { ...sobject, step3: "wait" }
        setSteps(sobject)
    }, [formData]);


    const handleSave = (status) => {
        let err = formatErrors(
            formData.bid_type == "manual" ? modelManualBid.check(formData) : modelAutoBid.check(formData)
        );
        err = !err ? null : err;
        if (!err)
            dispatch(insertCampaign({ ...formData, status: status }, history))
    }

    const handleCancel = () => {
        dispatch(resetCampaign(history))
    }
    return (
        <>
            <Content>

                <Panel className={styles.steps_custom}>
                    <Steps small current={1} >
                        <Steps.Item title="Add campaign name" status={steps.step1} />
                        <Steps.Item title="Select targeting" status={steps.step2} />
                        <Steps.Item title="Enter Bid details" status={steps.step3} />
                        <Steps.Item title="Save" status={steps.step4} />
                        {
                        /*<Steps.Item title="Save Error" status={"error"} />
                        <Steps.Item title="Save Sucessful" status={"finish"} />
                        */}
                    </Steps>
                </Panel>



                <Panel shaded className={styles.impact}>
                    <h5 className='pb-2'>Impact</h5>
                    {step2Condition(formData) && step3Condition(formData) ?
                        <Impact formData={formData} />
                        : <Message >{"Insert targeting and bid details to see impact"}</Message>}
                   

                </Panel>



                <Panel header={"New campaign (PPC)"} className={"wrap-panel  pt-5"}>


                    <Form
                        model={formData.bid_type == "manual" ? modelManualBid : modelAutoBid}
                        onChange={formValue => dispatch(presistChanges(formValue, ftype))}
                        formValue={formData}
                        style={{ width: 500 }}
                        layout="horizontal"
                        checkTrigger='none'
                    >

                        <Form.Group controlId={"name"}  >
                            <Form.ControlLabel className="text-left">{"Campaign name *"} </Form.ControlLabel>
                            <Form.Control name={"name"} style={{ width: 350 }} />
                        </Form.Group>


                        <h5>Targeting</h5>

                        <RadioTileGroup
                            name="targeting_type"
                            className={"py-3"}
                            aria-label="Targeting Type"
                            style={{ width: 500 }}
                            onChange={(value) => {
                                dispatch(presistChanges({ ...formData, targeting_type: value }, ftype))
                            }}
                        >
                            <RadioTile icon={<ListIcon />} label="Category" value="category">
                                Targeting products based on categories, you can select one or multiple categories in different levels.
                            </RadioTile>
                            <RadioTile icon={<ScatterIcon />} label="Category + Brand" value="category+brand">
                                Targeting products based on it's category and brand
                            </RadioTile>
                            <RadioTile icon={<AbTestIcon />} label="Brand" value="brand">
                                Targeting products based on one or multiple product brands.
                            </RadioTile>
                            <RadioTile icon={<FileUploadIcon />} label="Product" value="product">
                                Targeting based on custom list of product(s). You can paste your list of bracodes or find products via search
                            </RadioTile>
                        </RadioTileGroup>


                        {formData.targeting_type == "category" ?
                            <CategorySelection categories={categories} formData={formData} ftype={ftype} />
                            : ""}

                        {formData.targeting_type == "category+brand" ?
                            <>
                                <CategorySelection categories={categories} formData={formData} ftype={ftype} />
                                <BrandSelection brands={brands} formData={formData} ftype={ftype} />
                            </>
                            : ""}

                        {formData.targeting_type == "brand" ?
                            <BrandSelection brands={brands} formData={formData} ftype={ftype} />
                            : ""}

                        {formData.targeting_type == "product" ?
                            <ProductSelection products={products} formData={formData} ftype={ftype} user={user} selected_shop={selected_shop} />
                            : ""}


                        <Divider />

                        {step1Condition(formData) && step2Condition(formData) ?
                            <>
                                <h5 className='pb-4'>{"Bidding details"}</h5>


                                <RadioTileGroup
                                    name="bid_type"
                                    className={"py-3"}
                                    aria-label="Bidding Type"
                                    style={{ width: 500 }}
                                    onChange={(value) => {
                                        dispatch(presistChanges({ ...formData, bid_type: value }, ftype))
                                    }}
                                    inline
                                    value={formData.bid_type}
                                >
                                    <RadioTile icon={<AdminIcon />} label="Manual" value="manual" style={{ width: 250 }}>
                                        Manual tracking of bid performance and adjustments
                                    </RadioTile>
                                    <RadioTile icon={<TaskIcon />} label="Auto bidding" value="auto" style={{ width: 250 }}>
                                        Stays always on top with your bids by setting up autobidding
                                    </RadioTile>
                                </RadioTileGroup>



                                <NumberField name="bid" label="Bid (CPC)*" postfix={currency} step={0.005} min={0.005} max={1} help={"A bid is an offer made by you and which is competing against bids from other competitors. This amount is added on top of regular CPC costs in case won bid is clicked by visitor."} />
                                {formData.bid_type == "auto" ?
                                    <>
                                        <NumberField name="increment" label="Bid increment *" postfix={currency + " / per increment round"} step={0.005} max={1} min={0} help={"For how much system will auto increase your original bid, in each bid round."} />
                                        <NumberField name="max_bid" label="Max bid (CPC) *" postfix={currency + " / per bid"} step={0.005} min={0} max={1} help={"Set top floor limit for auto bidding and system will not go over it."} />
                                        <NumberField name="increment_rounds" label="Max increment rounds" min={0} step={1} help={"Limit total number of bid rounds system is allowed to increment original bid value."} />

                                    </>
                                    : ""}
                                <NumberField name="daily_budget" label="Daily budget" postfix={currency} step={1} min={0} help={"Limit daily cost for this campaign. If budget is spent, system will automaticly turn off campaign."} />
                                <NumberField name="budget" label="Campaign budget" postfix={currency} step={1} min={0} help={"Limit total cost for this campaign. If budget is spent, system will automaticly turn off campaign."} />
                                <StartDateField name={"start_date"} label="Start date" />
                                <EndDateField name={"end_date"} label="End date" />
                                <Divider />
                            </>
                            : <Message >{"Insert campaign name and target details to continue."}</Message>}

                        <ButtonToolbar className={styles.confirm_buttons}>
                            <Button
                                appearance="primary"
                                type="submit"
                                disabled={!step1Condition(formData) || !step2Condition(formData) || !step3Condition(formData)}
                                onClick={() => {
                                    handleSave("active")
                                }}
                                loading={loading}
                            >
                                Save
                            </Button>
                            <Button
                                type="submit"
                                disabled={!step1Condition(formData) || !step2Condition(formData) || !step3Condition(formData)}
                                onClick={() => {
                                    handleSave("draft")
                                }}
                                loading={loading}
                            >
                                Save as draft
                            </Button>
                            <Button
                                appearance="subtle" type="submit"
                                loading={loading}
                                onClick={() => {
                                    handleCancel()
                                }}
                            >
                                Cancel
                            </Button>
                        </ButtonToolbar>
                    </Form>
                </Panel>
            </Content>
        </>
    )
}
export default CampaignsPPC;
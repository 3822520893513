import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Content, Panel, FlexboxGrid, Table, Button } from 'rsuite';
import { getCampaigns } from './campaigns-redux';
import { numberFormat, canManageCampaigns, getAccountType } from '../../app/utils';

import { Link } from 'react-router-dom'
const { Column, HeaderCell, Cell } = Table;

const NavLink = React.forwardRef(({ href, children, ...rest }, ref) => (
    <Link ref={ref} to={href} {...rest}>
        {children}
    </Link>
));

const Campaigns = () => {


    const campaigns = useSelector(state => state.campaigns.data);
    const selected_shop = useSelector(state => state.base.selected_shop);
    const selected_account = useSelector(state => state.base.selected_account);
    const selected_brand = useSelector(state => state.base.selected_brand);
    const selected_website = useSelector(state => state.base.selected_website);
    const user = useSelector(state => state.base.user);

    const dispatch = useDispatch();
    useEffect(() => {
        // if (selected_account)
        //     dispatch(getCampaigns());
    }, [dispatch, selected_shop, selected_account, selected_brand, selected_website]);




    return (
        <>
            <Content>
                <Panel header={"Campaigns"} className={"wrap-panel"}>
                    <p>
                        {"Boost your revenue and visibility with our powerful campaigns."}
                    </p>
                    <p>
                        {"Increase your search ranking and position in price comparison table as you drive targeted traffic and gain more impressions. Take control of your success today!"}
                    </p>
                </Panel>

                <FlexboxGrid className={"px-4"}>
                    {getAccountType(user, selected_account) != "brand" ?
                        <FlexboxGrid.Item className={"pr-2"} style={{ width: 400 }}>
                            <Panel shaded>
                                <img src={process.env.REACT_APP_URL + "/ppc.png"} />
                                <h5 className={"py-3"}>{"Price display priority - PPC bid"}</h5>
                                <p className='pb-3'>
                                    {"Gain a competitive edge for your shop and elevate revenue performance by leveraging Price display priority through pay per click (PPC) bidding, ensuring improved visibility and strategic positioning in price comparison tables."}
                                </p>

                                {canManageCampaigns(user, selected_account) ?
                                    <Button appearance="primary" href="/campaigns/new-ppc" as={NavLink}>{campaigns.length > 0 ? "Add New" : "Add new"}</Button>
                                    : user && selected_account ?
                                        <Button appearance="primary" href="mailto:kontakt@cenoteka.rs?subject=Campaign%20Management">{"Contact us"}</Button>
                                        : ""
                                }
                            </Panel>
                        </FlexboxGrid.Item>
                        : ""}
                    <FlexboxGrid.Item className={"pr-2"} style={{ width: 400 }}>
                        <Panel shaded>
                            <img src={process.env.REACT_APP_URL + "/cpm.png"} />
                            <h5 className={"py-3"}>{"Listing position priority - CPM bid"}</h5>
                            <p className='pb-3'>
                                {"Secure a prominent position for your listings with Listing position priority through cost per 1000 impressions (CPM) bidding, enhancing visibility, brand awareness and price perception of visitors to maximize your shop / brand revenue potential."}
                            </p>
                            {canManageCampaigns(user, selected_account) ?
                                <Button appearance="primary" href="/campaigns/new-cpm" as={NavLink}>{campaigns.length > 0 ? "Add New" : "Add new"}</Button>
                                : user && selected_account ?
                                    <Button appearance="primary" href="mailto:kontakt@cenoteka.rs?subject=Campaign%20Management">{"Contact us"}</Button>
                                    : ""
                            }
                        </Panel>
                    </FlexboxGrid.Item>
                </FlexboxGrid>


            </Content>
        </>
    )
}

export default Campaigns;
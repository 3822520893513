import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import 'rsuite/dist/rsuite.min.css';
import Router from '../app/router';

import { getCsrfToken, getUserProfile } from './_base/base-redux';
import './_base/bootstrap.css'
import './_base/base.scss';
function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const csrf_acquired = useSelector(state => state.base.csrf_token_acquired)
  useEffect(() => {
    dispatch(getCsrfToken());
    if (csrf_acquired)
      dispatch(getUserProfile(history));
  }, [dispatch, history, csrf_acquired]);


  return (
    csrf_acquired ?

      <Router />

      : ""
  )
}

export default App;

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { LayoutPublic, LayoutPrivate } from '../components/_base/base'
import Login from '../components/login/login';
import ForgotLogin from '../components/login/forgot';
import ResetPassword from '../components/login/reset';
import Dashboard from '../components/dashboard/dashboard';
import ClickLogs from '../components/click-logs/click-logs';
import Insights from '../components/insights/insights';

import Campaigns from '../components/campaigns/campaigns';
import ActiveCampaigns from '../components/campaigns/active-campaigns';
import CampaignsReporting from '../components/campaigns/campaigns-reporting';
import NewCampaignPPC from '../components/campaigns/new-campaign-ppc';
import NewCampaignCPM from '../components/campaigns/new-campaign-cpm';

import EditCampaignPPC from '../components/campaigns/edit-campaign-ppc';
import EditCampaignCPM from '../components/campaigns/edit-campaign-cpm';

const Router = () => {
    return (
        <>
            <Switch>
                <RouteWrapper exact path="/" component={Login} layout={LayoutPublic} />
                <RouteWrapper exact path="/forgot" component={ForgotLogin} layout={LayoutPublic} />
                <RouteWrapper exact path="/reset-password/:token" component={ResetPassword} layout={LayoutPublic} />
                <RouteWrapper exact path="/dashboard" component={Dashboard} layout={LayoutPrivate} />
                <RouteWrapper exact path="/click-logs" component={ClickLogs} layout={LayoutPrivate} />
                <RouteWrapper exact path="/insights" component={Insights} layout={LayoutPrivate} />
                <RouteWrapper exact path="/campaigns" component={Campaigns} layout={LayoutPrivate} />
                <RouteWrapper exact path="/campaigns/new-ppc" component={NewCampaignPPC} layout={LayoutPrivate} />
                <RouteWrapper exact path="/campaigns/new-cpm" component={NewCampaignCPM} layout={LayoutPrivate} />
                <RouteWrapper exact path="/all-campaigns" component={ActiveCampaigns} layout={LayoutPrivate} />
                <RouteWrapper exact path="/campaigns-reporting" component={CampaignsReporting} layout={LayoutPrivate} />
                <RouteWrapper exact path="/campaigns/edit-ppc/:id" component={EditCampaignPPC} layout={LayoutPrivate} />
                <RouteWrapper exact path="/campaigns/edit-cpm/:id" component={EditCampaignCPM} layout={LayoutPrivate} />
            </Switch>
        </>
    );
}

function RouteWrapper({
    component: Component,
    layout: Layout,
    ...rest
}) {
    return (
        <Route {...rest} render={(props) =>
            <Layout {...props}>
                <Component {...props} />
            </Layout>
        } />
    );
}

export default Router;
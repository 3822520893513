import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table,  Badge, Placeholder } from 'rsuite';
import { getCampaignImpactDetailed, resetImpactDetailed } from '../campaigns-redux'



import CheckIcon from '@rsuite/icons/Check';
import CloseIcon from '@rsuite/icons/Close';

const { Column, HeaderCell, Cell } = Table;
const Impact = ({ formData }) => {
    const impact = useSelector(state => state.campaigns.impact);
    const dispatch = useDispatch();
    useEffect(() => {
        if (
            (formData.bid_type == "manual" && formData.bid) ||
            (formData.bid_type == "auto" && formData.bid && formData.max_bid && formData.increment)
        )
            dispatch(getCampaignImpactDetailed(formData))
        else
            dispatch(resetImpactDetailed())

    }, [formData])
    return (
        <>
            {!impact.loading ? <>
                <Table
                    className='pt-2'
                    data={impact.overall}
                    autoHeight
                >
                    <Column width={70} align="center" fixed>
                        <HeaderCell>Position</HeaderCell>
                        <Cell className='text-center' dataKey="position">
                            {rowData => (
                                <>
                                    {rowData.position == 1 ?
                                        <Badge color="green" content="1" className="mr-2 font-weight-bold" />
                                        : rowData.position == 2 ?
                                            <Badge color="yellow" content="2" className="mr-2 font-weight-bold" />
                                            : rowData.position == 3 ?
                                                <Badge color="orange" content="3" className="mr-2 font-weight-bold" />
                                                :
                                                <Badge color="red" content="4" className="mr-2 font-weight-bold" />
                                    }
                                </>
                            )}
                        </Cell>
                    </Column>
                    <Column width={120} align="center" >
                        <HeaderCell>Viewable</HeaderCell>
                        <Cell dataKey="viewable">
                            {rowData => (
                                rowData.viewable ? <CheckIcon /> : <CloseIcon />
                            )}
                        </Cell>
                    </Column>
                    {formData.targeting_type != "home" ?
                        <Column width={80} align="center">
                            <HeaderCell>Categories</HeaderCell>
                            <Cell dataKey="categories" />
                        </Column>
                        : ""}
                </Table>
            </> : <Placeholder.Paragraph style={{ marginTop: 30 }} active rows={6} />}
        </>
    )
}

export default Impact;
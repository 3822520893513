import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Content, Panel, FlexboxGrid, Table, Button, IconButton, Badge, MultiCascader, Placeholder, Modal } from 'rsuite';
import { getCampaigns, setFilters, updateCampaign } from './campaigns-redux';
import { canManageCampaigns, getAccountType } from '../../app/utils';
import { capitalize } from 'lodash'
import DatePicker from '../_common/date-picker'

import EditIcon from '@rsuite/icons/Edit';
import ArrowUpIcon from '@rsuite/icons/ArrowUp';
import ArrowDownIcon from '@rsuite/icons/ArrowDown';
import PlayIcon from '@rsuite/icons/legacy/Play';
import PauseIcon from '@rsuite/icons/legacy/Pause';
import ArchiveIcon from '@rsuite/icons/legacy/Archive';
import PlusIcon from '@rsuite/icons/Plus';
import MoveUpIcon from '@rsuite/icons/MoveUp';
import CheckIcon from '@rsuite/icons/Check';
import CloseIcon from '@rsuite/icons/Close';

import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';


const { Column, HeaderCell, Cell } = Table;




const ActiveCampaigns = () => {

    const campaigns = useSelector(state => state.campaigns.data);
    const campaigns_filters = useSelector(state => state.campaigns.filters);
    const campaigns_impacts = useSelector(state => state.campaigns.impacts);
    const campaigns_stats = useSelector(state => state.campaigns.stats);
    const currency = useSelector(state => state.base.website.currency);

    const selected_shop = useSelector(state => state.base.selected_shop);
    const selected_account = useSelector(state => state.base.selected_account);
    const selected_brand = useSelector(state => state.base.selected_brand);
    const selected_website = useSelector(state => state.base.selected_website);
    const user = useSelector(state => state.base.user);


    const [date, setDate] = useState({
        start: moment().startOf("month"),
        end: moment().endOf("month")
    })


    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        if (selected_account) {
            dispatch(getCampaigns(
                date.start.format('YYYY-MM-DD HH:mm:ss'),
                date.end.format('YYYY-MM-DD HH:mm:ss'),
            ));
        }
    }, [dispatch, selected_shop, selected_account, selected_brand, selected_website, date]);


    const [dataCampagins, setDataCampaigns] = useState([])
    useEffect(() => {
        let camp_arr = []
        for (const c of campaigns) {
            let campaign_obj = { ...c }
            let im = null
            for (const imp of campaigns_impacts) {
                if (imp.campaign_id == c.id)
                    im = imp

            }
            if (im)
                campaign_obj = { ...campaign_obj, impact: im }


            let st = null
            for (const stat of campaigns_stats) {
                if (stat.campaign_id == c.id)
                    st = stat

            }
            if (st)
                campaign_obj = { ...campaign_obj, stats: st }


            camp_arr.push(campaign_obj)
        }
        setDataCampaigns(camp_arr)
    }, [dispatch, campaigns, campaigns_impacts, campaigns_stats]);


    const TrendIcon = ({ number }) => {
        return (
            <>
                {number > 0 ? <ArrowUpIcon color="green" /> : ""}
                {number < 0 ? <ArrowDownIcon color="red" /> : ""}
            </>
        )
    }

    const handleStatusChange = (data, status) => {
        dispatch(updateCampaign({ ...data, status: status }, history));
    }

    const handleStatusFilterChange = (filters) => {
        if (filters && filters.length > 0) {
            dispatch(setFilters({ ...campaigns_filters, status: [...filters] }));
        } else {
            dispatch(setFilters({ ...campaigns_filters, status: ["active", "paused", "draft", "daily_budg", "completed"] }));
        }
        setStatusFilter(filters);
        dispatch(getCampaigns());
    }

    const [statusFilter, setStatusFilter] = useState([])


    const [openModal, setOpenModal] = useState(false);
    const [campaignToArchive, setCampaignToArchive] = useState(null);

    // Step 3: Function to open and close modal
    const openArchiveModal = (campaign) => {
        setCampaignToArchive(campaign);
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    return (
        <>
            <Content>
                <h5 className='pl-4 pb-4'>{"CAMPAIGNS"}</h5>

                {canManageCampaigns(user, selected_account) ?
                    <>
                        <FlexboxGrid>


                            <FlexboxGrid.Item className='pl-4'>
                                <DatePicker
                                    className="ml-3"
                                    placeholder="Select date range"
                                    onChange={(value) => {
                                        setDate({
                                            start: moment(value[0]).startOf("day"),
                                            end: moment(value[1]).endOf("day")
                                        })
                                    }}
                                    value={[
                                        new Date(date.start.format("YYYY-MM-DD")),
                                        new Date(date.end.format("YYYY-MM-DD"))
                                    ]}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className='pl-2'>

                                <MultiCascader
                                    placeholder="Status Filter"
                                    style={{ width: 200 }}
                                    menuWidth={200}
                                    menuHeight={200}
                                    data={[
                                        {
                                            label: "Active",
                                            value: "active"
                                        },
                                        {
                                            label: "Paused",
                                            value: "paused"
                                        },
                                        {
                                            label: "Draft",
                                            value: "draft"
                                        },
                                        {
                                            label: "Daily Budget Reached",
                                            value: "daily_budg"
                                        },
                                        {
                                            label: "Completed",
                                            value: "completed"
                                        },
                                        {
                                            label: "Archived",
                                            value: "archived"
                                        },

                                    ]}
                                    value={statusFilter}
                                    searchable={false}
                                    onChange={(value) => { handleStatusFilterChange(value) }}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className='pr-4 ml-auto'>
                                <Button appearance="primary" startIcon={<PlusIcon />} onClick={() => { history.push("/campaigns/new-cpm") }}>CPM campaign</Button>
                            </FlexboxGrid.Item>

                            {getAccountType(user, selected_account) != "brand" ?
                                <FlexboxGrid.Item className='pr-2'>
                                    <Button appearance="primary" startIcon={<PlusIcon />} onClick={() => { history.push("/campaigns/new-ppc") }}>CPC campaign</Button>
                                </FlexboxGrid.Item>
                                : ""}

                           
                        </FlexboxGrid>

                        <Panel className={"wrap-panel"} >
                            <Panel shaded>
                                <Table autoHeight data={dataCampagins} wordWrap="break-word" bordered cellBordered headerHeight={60} className={"all_campaigns"} style={{ maxWidth: 1880 }}>
                                    <Column width={50} verticalAlign="middle">
                                        <HeaderCell>{"ID"}</HeaderCell>
                                        <Cell dataKey="id" />
                                    </Column>

                                    <Column width={160} verticalAlign="middle">
                                        <HeaderCell>{"Campaign"}</HeaderCell>
                                        <Cell dataKey="name" />
                                    </Column>
                                    <Column width={80} verticalAlign="middle">
                                        <HeaderCell>{"Status"}</HeaderCell>
                                        <StatusCell dataKey="status" />
                                    </Column>
                                    <Column width={70} align="center" verticalAlign="middle">
                                        <HeaderCell>{"Campaign type"}</HeaderCell>
                                        <Cell>
                                            {rowData => (
                                                <>
                                                    {rowData.campaign_type.toUpperCase()}
                                                </>
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column width={80} align="center" verticalAlign="middle">
                                        <HeaderCell>{"Targeting"}</HeaderCell>
                                        <Cell>
                                            {rowData => (
                                                <>
                                                    {rowData.targeting_type == "category+brand" ? "Category + Brand" : capitalize(rowData.targeting_type)}
                                                </>
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column width={70} align="center" verticalAlign="middle">
                                        <HeaderCell>{"Bidding type"}</HeaderCell>
                                        <Cell>
                                            {rowData => (
                                                <>
                                                    {capitalize(rowData.bid_type)}
                                                </>
                                            )}
                                        </Cell>
                                    </Column>

                                    <Column width={70} align="center" verticalAlign="middle">
                                        <HeaderCell>{`Bid`}</HeaderCell>
                                        <Cell>
                                            {rowData => (
                                                <>
                                                    {rowData.bid ? rowData.bid + " " + currency : "-"}
                                                </>
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column width={70} align="center" verticalAlign="middle">
                                        <HeaderCell>{`Max Bid`}</HeaderCell>
                                        <Cell>
                                            {rowData => (
                                                <>
                                                    {rowData.max_bid ? rowData.max_bid + " " + currency : "-"}
                                                </>
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column width={90} align="center" verticalAlign="middle">
                                        <HeaderCell>{"Impressions*"}</HeaderCell>
                                        <Cell>
                                            {rowData => (
                                                <>
                                                    {rowData.stats && rowData.stats.impressions ? rowData.stats.impressions : "-"}
                                                </>
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column width={60} align="center" verticalAlign="middle">
                                        <HeaderCell>{"Clicks"}</HeaderCell>
                                        <Cell>
                                            {rowData => (
                                                <>
                                                    {rowData.stats && rowData.stats.clicks ? rowData.stats.clicks : "-"}
                                                </>
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column width={80} align="center" verticalAlign="middle">
                                        <HeaderCell>{"CTR %"}</HeaderCell>
                                        <Cell>
                                            {rowData => (
                                                <>
                                                    {rowData.stats && rowData.stats.clicks && rowData.stats.impressions ? (Math.round(((rowData.stats.clicks / rowData.stats.impressions) * 100) * 100) / 100) + "%" : "-"}
                                                </>
                                            )}
                                        </Cell>
                                    </Column>


                                    <Column width={70} align="center" verticalAlign="middle">
                                        <HeaderCell>{`Budget`}</HeaderCell>
                                        <Cell>
                                            {rowData => (
                                                <>
                                                    {rowData.budget ? rowData.budget + " " + currency : "-"}
                                                </>
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column width={70} align="center" verticalAlign="middle">
                                        <HeaderCell>{`Budget (daily)`}</HeaderCell>
                                        <Cell>
                                            {rowData => (
                                                <>
                                                    {rowData.budget_daily ? rowData.budget_daily + " " + currency : "-"}
                                                </>
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column width={100} align="center" verticalAlign="middle">
                                        <HeaderCell>{`Total spent (bidding)`}</HeaderCell>
                                        <Cell>
                                            {rowData => (
                                                <>
                                                    {rowData.stats && rowData.stats.total_spent ? rowData.stats.total_spent + " " + currency : "-"}
                                                </>
                                            )}
                                        </Cell>
                                    </Column>

                                    <Column width={100} align="center" verticalAlign="middle">
                                        <HeaderCell>{`Total spent (base cost)`}</HeaderCell>
                                        <Cell>
                                            {rowData => (
                                                <>
                                                    {rowData.stats && rowData.stats.total_spent_campaign_and_regular && rowData.stats.total_spent ? (Math.round((rowData.stats.total_spent_campaign_and_regular - rowData.stats.total_spent) * 100) / 100) + " " + currency : "-"}
                                                </>
                                            )}
                                        </Cell>
                                    </Column>

                                    <Column width={100} align="center" verticalAlign="middle">
                                        <HeaderCell>{`Total spent`}</HeaderCell>
                                        <Cell>
                                            {rowData => (
                                                <>
                                                    {rowData.stats && rowData.stats.total_spent_campaign_and_regular ? rowData.stats.total_spent_campaign_and_regular + " " + currency : "-"}
                                                </>
                                            )}
                                        </Cell>
                                    </Column>


                                    <Column width={150} style={{ fontSize: 12 }} verticalAlign='middle'>
                                        <HeaderCell>{"Impact"}</HeaderCell>
                                        <ImpactCell dataKey={"impact"} />
                                    </Column>
                                    <Column width={100} verticalAlign="middle" align="center" style={{ fontSize: 12 }}>
                                        <HeaderCell>{"Start"}</HeaderCell>
                                        <Cell>
                                            {rowData => (
                                                <>
                                                    {moment(rowData.start_date).isValid() ? moment(rowData.start_date).format('DD.MM.YYYY HH:mm') : ""}
                                                </>
                                            )}
                                        </Cell>
                                    </Column>
                                    <Column width={100} verticalAlign="middle" align="center" style={{ fontSize: 12 }}>
                                        <HeaderCell>{"End"}</HeaderCell>
                                        <Cell>
                                            {rowData => (
                                                <>
                                                    {moment(rowData.end_date).isValid() ? moment(rowData.end_date).format('DD.MM.YYYY HH:mm') : "Unlimited"}
                                                </>
                                            )}
                                        </Cell>
                                    </Column>

                                    <Column width={170} verticalAlign="middle" align="center" fixed="right">
                                        <HeaderCell>Actions</HeaderCell>
                                        <Cell style={{ paddingTop: '10px', paddingRight: '20px' }}>
                                            {rowData => (
                                                <>
                                                    {rowData.status != "active" && rowData.status != "archived" ?
                                                        <IconButton
                                                            circle
                                                            size="md"
                                                            color="green"
                                                            appearance="subtle"
                                                            onClick={() => handleStatusChange(rowData, "active")}
                                                            icon={<PlayIcon />}
                                                            className="ml-1"
                                                            title={"Play campaign"}
                                                        />
                                                        : ""}

                                                    {rowData.status == "active" ?
                                                        <IconButton
                                                            circle
                                                            size="md"
                                                            color="yellow"
                                                            appearance="subtle"
                                                            onClick={() => handleStatusChange(rowData, "paused")}
                                                            icon={<PauseIcon />}
                                                            className="ml-1"
                                                            title={"Pause campaign"}
                                                        />
                                                        : ""}

                                                    <IconButton
                                                        circle
                                                        size="md"
                                                        appearance="subtle"
                                                        onClick={() => {
                                                            console.log()
                                                            if (rowData.campaign_type == "cpc")
                                                                history.push("/campaigns/edit-ppc/" + rowData.id)
                                                            else if (rowData.campaign_type == "cpm")
                                                                history.push("/campaigns/edit-cpm/" + rowData.id)
                                                        }}
                                                        icon={<EditIcon />}
                                                        className="ml-1"
                                                        title={"Edit campaign"}
                                                    />

                                                    {rowData.status == "archived" ?
                                                        <IconButton
                                                            circle
                                                            size="md"
                                                            appearance="subtle"
                                                            onClick={() => handleStatusChange(rowData, "paused")}
                                                            icon={<MoveUpIcon />}
                                                            className="ml-1"
                                                            title={"Reactivate campaign"}
                                                        />
                                                        : ""}
                                                    {rowData.status != "archived" ?
                                                        <IconButton
                                                            circle
                                                            size="md"
                                                            appearance="subtle"
                                                            onClick={() => openArchiveModal(rowData)}
                                                            icon={<ArchiveIcon />}
                                                            className="ml-1"
                                                            title={"Archive campaign"}
                                                        />
                                                        : ""}

                                                </>
                                            )}
                                        </Cell>
                                    </Column>
                                </Table>
                                <div className='pt-4 '>{"* Impressions are sourced from GA4 and may experience a delay of up to 24 hours."}</div>
                            </Panel>

                            <Modal
                                open={openModal} // Use 'show' instead of 'open'
                                onClose={handleClose} // Use 'onHide' instead of 'onClose'
                            >
                                <Modal.Header>
                                    <Modal.Title>Archive {campaignToArchive ? '"' + campaignToArchive.name + '"' : ""}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {/* You can customize the modal content here */}
                                    <p>Are you sure you want to archive this campaign?</p>
                                    <p>You can see all archived campaigns by selecting status "Archived" from top bar filter and you can reactivate them later.</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={handleClose} >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            // Handle campaign archive here
                                            // You can call your archiveCampaign function here
                                            // Pass 'campaignToArchive' to your archive function
                                            handleStatusChange(campaignToArchive, "archived");
                                            handleClose(); // Close the modal after archive
                                        }}
                                        appearance="primary"
                                    >
                                        Archive
                                    </Button>
                                </Modal.Footer>
                            </Modal>


                        </Panel>
                    </>
                    : user && selected_account ?
                        <Panel className={"wrap-panel"} style={{ maxWidth: 600 }} >
                            <p>{"If you're interested in elevating your campaign management capabilities on our platform, please reach out to our team."}</p>
                            <p>{"By leveraging our campaigns, you can significantly increase the Click-Through Rate (CTR) leading from our site to yours. This not only enhances the visibility of your offerings but also drives potential sales growth. Don't miss out on this opportunity to maximize your online presence and boost your sales."}</p>
                            <p><a style={{ color: "#822165" }} href="mailto:kontakt@cenoteka.rs?subject=Campaign%20Management">{"Contact us today!"}</a></p>
                        </Panel>
                        : ""
                }


            </Content>
        </>
    )
}


export const StatusCell = ({ rowData, dataKey, ...props }) => {
    return (
        <Cell {...props}>
            <Badge className={"status-badge " + (rowData.status ? rowData.status.toLowerCase() : rowData.pstatus ? rowData.pstatus.toLowerCase() : "")} content={rowData.status ? rowData.status.toLowerCase() : rowData.pstatus ? rowData.pstatus.toLowerCase() : ""} />
        </Cell>
    );
};

export const ImpactCell = ({ rowData, dataKey, ...props }) => {
    return (
        <Cell {...props}>

            {rowData.impact && rowData.impact.overall && rowData.impact.overall.length > 0 && rowData.status == "active" ?
                rowData.impact.overall.map((imp, index) => (


                    rowData.campaign_type == "cpc" ?
                        <FlexboxGrid key={index} className={index > 0 ? "mt-1" : ""}>
                            <Badge color={index == 0 ? "green" : index == 1 ? "yellow" : "orange"} content={index + 1} className="mr-2  font-weight-bold" />
                            <div style={{ width: 50, textAlign: "center" }} className='font-weight-bold'>{imp.products}</div>
                            <div> {" (" + Math.round((imp.percentage_total * 100) * 100) / 100 + "%)"}</div>
                        </FlexboxGrid>
                        : rowData.campaign_type == "cpm" && rowData.targeting_type == "category" ?
                            <FlexboxGrid key={index} className={index > 0 ? "mt-1" : ""}>
                                <Badge color={index == 0 ? "green" : index == 1 ? "yellow" : index == 2 ? "orange" : "red"} content={index + 1} className="mr-2  font-weight-bold" />
                                <div style={{ width: 50, textAlign: "center" }} className='font-weight-bold'>{imp.viewable ? <CheckIcon /> : <CloseIcon />}</div>
                                <div> {" (" + imp.categories + ")"} </div>
                            </FlexboxGrid>
                            : rowData.campaign_type == "cpm" && rowData.targeting_type == "home" ?
                                <FlexboxGrid key={index} className={index > 0 ? "mt-1" : ""}>
                                    <Badge color={index == 0 ? "green" : index == 1 ? "yellow" : index == 2 ? "orange" : "red"} content={index + 1} className="mr-2  font-weight-bold" />
                                    <div style={{ width: 50, textAlign: "center" }} className='font-weight-bold'>{imp.viewable ? <CheckIcon /> : <CloseIcon />}</div>
                                </FlexboxGrid> : ""

                ))
                : rowData.status == "paused" || rowData.status == "archived" || rowData.status == "draft" || rowData.status == "completed" || rowData.status == "daily_budg" ? <div className='text-center'><small>{"INACTIVE"}</small></div> : <Placeholder.Paragraph active rows={2} />}

        </Cell>
    );
};


export default ActiveCampaigns;
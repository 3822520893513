import ptekaApi from '../../app/services';
import { showError, showSuccess } from '../_base/base-redux'

//CONSTANTS
export const LOADING_CAMPAIGNS = 'LOADING_CAMPAIGNS'
export const GET_CAMPAIGNS = 'GET_CAMPAIGNS'
export const GET_CAMPAIGN = 'GET_CAMPAIGN'
export const GET_CATEGORIES_SELCTION = 'GET_CATEGORIES_SELCTION'
export const GET_PRODUCTS_SELCTION = 'GET_PRODUCTS_SELCTION'
export const GET_BRANDS_SELCTION = 'GET_BRANDS_SELCTION'
export const PERSIST_CHANGES_CAMPAIGN = 'PERSIST_CHANGES_CAMPAIGN'
export const SET_FORM_TYPE = 'SET_FORM_TYPE'
export const ADD_PRODUCT_BY_BARCODE = 'ADD_PRODUCT_BY_BARCODE'

export const GET_IMPACT_DETAILED = 'GET_IMPACT_DETAILED'
export const LOADING_IMPACT_DETAILED = 'LOADING_IMPACT_DETAILED'
export const RESET_IMPACT_DETAILED = 'RESET_IMPACT_DETAILED'

export const RESET_CAMPAIGN_IMPACTS_STATS = 'RESET_CAMPAIGN_IMPACTS_STATS'
export const SET_IMPACTS = 'SET_IMPACTS'
export const SET_STATS = 'SET_STATS'

// export const INSERT_CAMPAIGN = 'INSERT_CAMPAIGN'
// export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN'
// export const ARCHIVE_CAMPAIGN = 'ARCHIVE_CAMPAIGN'
export const RESET_CAMPAIGN = 'RESET_CAMPAIGN'
export const SET_FILTERS = 'SET_FILTERS'



//INITIAL STATE BID
const INITIAL_STATE_CAMPAIGN = {
    name: "",
    campaign_type: "",
    targeting_type: "",
    targeting_mode: "",
    tags: [],
    categories: [],
    brands: [],
    product_variations: [],
    bid_type: "manual",
    bid: 0,
    budget: 0,
    daily_budget: 0,
    start_date: new Date(),
    end_date: null,
    increment: null,
    increment_rounds: 0,
    max_bid: 0,
    status: "draft"
}

//INITIAL STATE
const INITIAL_STATE = {
    data: [],
    categories_selection: [],
    brands_selection: [],
    products_selection: [],
    form_type: "new",
    new: {
        ...INITIAL_STATE_CAMPAIGN
    },
    edit: {
        ...INITIAL_STATE_CAMPAIGN
    },
    impacts: [

    ],
    stats: [],
    impact: {
        total_targeted: 0,
        overall: [],
        categories: [],
        brand_categories: [],
        brands: [],
        products: [],
        loading: false
    },
    loading: false,
    filters: {
        status: ["active", "paused", "draft", "daily_budg", "completed"]
    }
};

const dataReFormat = (object) => {

    if (object.product_variations && object.product_variations.length > 0) {
        let variations = []
        for (const v of object.product_variations) {
            if (v.id_numeric)
                variations.push({ ...v, id: v.id_numeric })
            else
                variations.push(v)
        }
        object.product_variations = [...variations]
    }

    return {
        ...object,
        bid: Math.round(parseFloat(object.bid) * 1000) / 1000,
        budget: Math.round(parseFloat(object.budget) * 100) / 100,
        daily_budget: Math.round(parseFloat(object.daily_budget) * 100) / 100,
        increment: Math.round(parseFloat(object.increment) * 1000) / 1000,
        increment_rounds: parseInt(object.increment_rounds),
        max_bid: Math.round(parseFloat(object.max_bid) * 1000) / 1000,
    }
}


//REDUCERS
export const campaignsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case LOADING_CAMPAIGNS:
            return {
                ...state,
                loading: action.payload
            };

        case GET_CAMPAIGNS:
            return {
                ...state,
                data: action.payload
            };

        case GET_CAMPAIGN:
            return {
                ...state,
                edit: action.payload
            };


        case GET_CATEGORIES_SELCTION:
            return {
                ...state,
                categories_selection: action.payload
            };

        case GET_PRODUCTS_SELCTION:
            return {
                ...state,
                products_selection: action.payload
            };

        case GET_BRANDS_SELCTION:
            return {
                ...state,
                brands_selection: action.payload
            };


        case SET_FORM_TYPE:
            return {
                ...state,
                form_type: action.payload
            };


        case ADD_PRODUCT_BY_BARCODE:
            return {
                ...state,
                [`${action.payload.ftype}`]: {
                    ...state[`${action.payload.ftype}`],
                    product_variations: [...state[`${action.payload.ftype}`].product_variations, action.payload.product],
                }
            };

        case PERSIST_CHANGES_CAMPAIGN:
            return {
                ...state,
                [`${action.payload.ftype}`]: {
                    ...action.payload.data,
                    categories: [...action.payload.data.categories],
                    brands: [...action.payload.data.brands],
                    product_variations: [...action.payload.data.product_variations],
                }
            };


        case RESET_CAMPAIGN:
            return {
                ...state,
                new: {
                    ...INITIAL_STATE_CAMPAIGN
                },
                edit: {
                    ...INITIAL_STATE_CAMPAIGN
                }
            };

        case LOADING_IMPACT_DETAILED:
            return {
                ...state,
                impact: {
                    ...state.impact,
                    loading: action.payload
                }
            };
        case GET_IMPACT_DETAILED:
            return {
                ...state,
                impact: action.payload
            };

        case SET_IMPACTS:
            return {
                ...state,
                impacts: [
                    ...state.impacts,
                    action.payload
                ]
            };

        case SET_STATS:
            return {
                ...state,
                stats: [
                    ...state.stats,
                    action.payload
                ]
            };


        case SET_FILTERS:
            return {
                ...state,
                filters: { ...action.payload }
            };

        case RESET_IMPACT_DETAILED:
            return {
                ...state,
                impact: {
                    overall: [],
                    categories: [],
                    brand_categories: [],
                    brands: [],
                    products: [],
                },
            };
        case RESET_CAMPAIGN_IMPACTS_STATS:
            return {
                ...state,
                impacts: [],
                stats: []
            };


        default:
            return state;
    }
}


//ACTIONS

export const getCampaigns = (date_from, date_to) => (dispatch, getState) => {
    dispatch({ type: LOADING_CAMPAIGNS, payload: true })
    const { selected_account } = getState().base;
    const { selected_shop } = getState().base;
    const { selected_website } = getState().base;
    const { selected_brand } = getState().base;
    const { campaigns } = getState();

    ptekaApi.get('/campaigns', {
        params: {
            account_id: selected_account,
            shops: selected_shop ? [selected_shop] : [],
            websites: selected_website ? [selected_website] : [],
            brands: selected_brand ? [selected_brand] : [],
            ...campaigns.filters
        }
    })
        .then(res => {
            dispatch({ type: RESET_CAMPAIGN_IMPACTS_STATS })
            dispatch({ type: GET_CAMPAIGNS, payload: res.data.data })
            dispatch({ type: LOADING_CAMPAIGNS, payload: false })
            dispatch(getCampaignsImpacts(res.data.data))
            dispatch(getCampaignsStats(res.data.data, date_from, date_to))
        })
        .catch(err => {
            dispatch({ type: LOADING_CAMPAIGNS, payload: false })
            dispatch(showError(err))
        });
};

export const getCampaign = (id) => (dispatch, getState) => {
    dispatch({ type: LOADING_CAMPAIGNS, payload: true })
    const { selected_account } = getState().base;
    const { selected_shop } = getState().base;
    const { selected_website } = getState().base;
    const { selected_brand } = getState().base;
    ptekaApi.get('/campaigns/' + id, {
        params: {
            account_id: selected_account,
            shops: selected_shop ? [selected_shop] : [],
            websites: selected_website ? [selected_website] : [],
            brands: selected_brand ? [selected_brand] : []
        }
    })
        .then(res => {
            dispatch({ type: GET_CAMPAIGN, payload: res.data.data })
            dispatch({ type: LOADING_CAMPAIGNS, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_CAMPAIGNS, payload: false })
            dispatch(showError(err))
        });
};


export const insertCampaign = (data, history) => (dispatch, getState) => {

    dispatch({ type: LOADING_CAMPAIGNS, payload: true })
    const { selected_account } = getState().base;
    const { selected_shop } = getState().base;
    const { selected_website } = getState().base;
    const { selected_brand } = getState().base;

    if (!selected_account || !selected_website) {
        dispatch(showError("Please select Account & Website in sidemenu"))
        return
    }

    ptekaApi.post('/campaigns', dataReFormat(data), {
        params: {
            account_id: selected_account,
            shop_id: selected_shop,
            website_id: selected_website,
            brands: selected_brand ? [selected_brand] : []
        }
    })
        .then(res => {
            dispatch({ type: LOADING_CAMPAIGNS, payload: false })
            dispatch({ type: RESET_CAMPAIGN })
            dispatch(showSuccess(res.data.message ? res.data.message : "Succesfuly created campaign"))
            history.push("/all-campaigns")
        })
        .catch(err => {
            dispatch({ type: LOADING_CAMPAIGNS, payload: false })
            dispatch(showError(err))
        });
};

export const updateCampaign = (data, history) => (dispatch, getState) => {
    dispatch({ type: LOADING_CAMPAIGNS, payload: true })
    const { selected_account } = getState().base;
    const { selected_shop } = getState().base;
    const { selected_website } = getState().base;
    const { selected_brand } = getState().base;

    if (!selected_account || !selected_website) {
        dispatch(showError("Please select Account & Website in sidemenu"))
        return
    }


    ptekaApi.put('/campaigns/' + data.id, dataReFormat(data), {
        params: {
            account_id: selected_account,
            shop_id: selected_shop,
            website_id: selected_website,
            brands: selected_brand ? [selected_brand] : []
        }
    })
        .then(res => {
            dispatch(getCampaigns())
            dispatch({ type: RESET_CAMPAIGN })
            dispatch(showSuccess(res.data.message ? res.data.message : "Succesfuly updated campaign"))
            history.push("/all-campaigns")
        })
        .catch(err => {
            dispatch({ type: LOADING_CAMPAIGNS, payload: false })
            dispatch(showError(err))
        });
};




export const getInitialSelection = () => (dispatch, getState) => {
    const { selected_website } = getState().base;
    const { selected_brand } = getState().base;
    ptekaApi.get('/categories', {
        params: {
            display_type: "categories_selection",
            website_id: selected_website
        }
    })
        .then(res => {
            dispatch({ type: GET_CATEGORIES_SELCTION, payload: res.data.data })
        })
        .catch(err => {
            dispatch(showError(err))
        });

    if (selected_brand) {
        ptekaApi.get('/brands/' + selected_brand, {
            params: {
                //display_type: "brands_selection",
                website_id: selected_website,
            }
        })
            .then(res => {
                dispatch({ type: GET_BRANDS_SELCTION, payload: [res.data.data] })
            })
            .catch(err => {
                dispatch(showError(err))
            });
    } else {

        ptekaApi.get('/brands', {
            params: {
                display_type: "brands_selection",
                website_id: selected_website
            }
        })
            .then(res => {
                dispatch({ type: GET_BRANDS_SELCTION, payload: res.data.data })
            })
            .catch(err => {
                dispatch(showError(err))
            });
    }
};

export const getProductsSelection = (s, selected_shop_slug, categories, selected_brand_name) => (dispatch, getState) => {
    const { selected_website } = getState().base;
    ptekaApi.get('/search/products', {
        params: {
            s: s,
            rows: 20,
            fields: "id,name,id_numeric,thumb",
            shop: selected_shop_slug,
            categories: categories,
            "brands[]": selected_brand_name,
            website_id: selected_website
        }
    })
        .then(res => {
            dispatch({ type: GET_PRODUCTS_SELCTION, payload: res.data.data })
        })
        .catch(err => {
            dispatch(showError(err))
        });

};

export const getProductsSelectionByBarcodes = (barcodes, selected_shop_slug, ftype, selected_brand_name) => (dispatch, getState) => {
    const { selected_website } = getState().base;
    for (const barcode of barcodes)
        ptekaApi.get('/search/products', {
            params: {
                barcode: barcode,
                rows: 1,
                fields: "id,name,id_numeric,thumb",
                shop: selected_shop_slug,
                "brands[]": selected_brand_name,
                facets: 0,
                website_id: selected_website
            }
        })
            .then(res => {
                if (res && res.data && res.data.data && res.data.data[0])
                    dispatch({ type: ADD_PRODUCT_BY_BARCODE, payload: { ftype: ftype, product: res.data.data[0] } })
            })
            .catch(err => {
                dispatch(showError(err))
            });
};

export const resetCampaign = (history) => dispatch => {
    dispatch({ type: RESET_CAMPAIGN })
    history.push("/all-campaigns")
};

export const presistChanges = (data, ftype) => dispatch => {
    dispatch({ type: PERSIST_CHANGES_CAMPAIGN, payload: { data: data, ftype: ftype } })
};
export const setFormType = (ftype) => dispatch => {
    dispatch({ type: SET_FORM_TYPE, payload: ftype })
};


export const getCampaignImpactDetailed = (data) => (dispatch, getState) => {
    dispatch({ type: LOADING_IMPACT_DETAILED, payload: true })
    const { selected_account } = getState().base;
    const { selected_shop } = getState().base;
    const { selected_website } = getState().base;
    const { selected_brand } = getState().base;

    if (!selected_account || !selected_website) {
        dispatch(showError("Please select Account and Website in sidemenu"))
        return
    }

    ptekaApi.post('/campaigns_impact/' + (data.id ? data.id : 0), dataReFormat(data), {
        params: {
            account_id: selected_account,
            shop_id: selected_shop,
            website_id: selected_website,
            brands: selected_brand ? [selected_brand] : []
        }
    })
        .then(res => {
            dispatch({ type: GET_IMPACT_DETAILED, payload: res.data.data })
            dispatch({ type: LOADING_IMPACT_DETAILED, payload: false })
        })
        .catch(err => {
            dispatch({ type: LOADING_IMPACT_DETAILED, payload: false })
            dispatch(showError(err))
        });
};

export const getCampaignsImpacts = (campaigns) => (dispatch, getState) => {
    const { selected_account } = getState().base;
    const { selected_shop } = getState().base;
    const { selected_website } = getState().base;
    const { selected_brand } = getState().base;

    if (!selected_account || !selected_website) {
        dispatch(showError("Please select Account and Website in sidemenu"))
        return
    }

    for (const campaign of campaigns) {
        if (campaign.status == "active")
            ptekaApi.post('/campaigns_impact/' + (campaign.id), dataReFormat(campaign), {
                params: {
                    account_id: selected_account,
                    shop_id: selected_shop,
                    website_id: selected_website,
                    brands: selected_brand ? [selected_brand] : []
                }
            })
                .then(res => {
                    dispatch({ type: SET_IMPACTS, payload: { campaign_id: campaign.id, ...res.data.data } })
                })
                .catch(err => {
                    //dispatch({ type: LOADING_IMPACT_DETAILED, payload: false })
                    dispatch(showError(err))
                });
    }
};

export const getCampaignsStats = (campaigns, date_from, date_to) => (dispatch, getState) => {
    const { selected_account } = getState().base;
    const { selected_shop } = getState().base;
    const { selected_website } = getState().base;
    const { selected_brand } = getState().base;

    if (!selected_account || !selected_website) {
        dispatch(showError("Please select Account and Website in sidemenu"))
        return
    }

    for (const campaign of campaigns) {
        ptekaApi.get('/campaigns_stats/' + (campaign.id), {
            params: {
                account_id: selected_account,
                shop_id: selected_shop,
                website_id: selected_website,
                brands: selected_brand ? [selected_brand] : [],
                date_from: date_from ? date_from : null,
                date_to: date_to ? date_to : null
            }
        })
            .then(res => {
                dispatch({ type: SET_STATS, payload: { campaign_id: campaign.id, ...res.data.data } })
            })
            .catch(err => {
                //dispatch({ type: LOADING_IMPACT_DETAILED, payload: false })
                dispatch(showError(err))
            });
    }
};

export const resetImpactDetailed = () => dispatch => {
    dispatch({ type: RESET_IMPACT_DETAILED })
};


export const setFilters = (filters) => dispatch => {
    dispatch({ type: SET_FILTERS, payload: filters })
};




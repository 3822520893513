import ptekaApi from '../../app/services';
import { showError } from '../_base/base-redux'

//CONSTANTS
export const LOADING_LIKES = 'LOADING_LIKES'
export const GET_LIKES = 'GET_LIKES'

export const LOADING_VIEWS = 'LOADING_VIEWS'
export const GET_VIEWS = 'GET_VIEWS'

export const LOADING_CTRS = 'LOADING_CTRS'
export const GET_CTRS = 'GET_CTRS'

//INITIAL STATE
const INITIAL_STATE = {
    likes: {
        data: [],
        loading: false
    },
    views: {
        data: [],
        loading: false
    },
    ctrs: {
        data: [],
        loading: false
    }
};


//REDUCERS
export const insightsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case LOADING_LIKES:
            return {
                ...state,
                likes: {
                    ...state.likes,
                    loading: action.payload
                }
            };

        case GET_LIKES:
            return {
                ...state,
                likes: {
                    ...state.likes,
                    data: action.payload
                }
            };


        case LOADING_VIEWS:
            return {
                ...state,
                views: {
                    ...state.views,
                    loading: action.payload
                }
            };

        case GET_VIEWS:
            return {
                ...state,
                views: {
                    ...state.views,
                    data: action.payload
                }
            };


        case LOADING_CTRS:
            return {
                ...state,
                ctrs: {
                    ...state.ctrs,
                    loading: action.payload
                }
            };

        case GET_CTRS:
            return {
                ...state,
                ctrs: {
                    ...state.ctrs,
                    data: action.payload
                }
            };


        default:
            return state;
    }
}


//ACTIONS

export const getViews = () => (dispatch, getState) => {
    dispatch({ type: LOADING_VIEWS, payload: true })
    const { selected_website } = getState().base;
    ptekaApi.get('/search/products', {
        params: {
            rows: 50,
            sort_by: "views",
            sort: "DESC",
            website_id: selected_website
        }
    })
        .then(res => {
            dispatch({ type: LOADING_VIEWS, payload: false })
            dispatch({ type: GET_VIEWS, payload: res.data.data })
        })
        .catch(err => {
            dispatch({ type: LOADING_VIEWS, payload: false })
            dispatch(showError(err))
        });
};


export const getCtrs = () =>(dispatch, getState) => {
    // dispatch({ type: LOADING_CTRS, payload: true })
    // const { selected_website } = getState().base;
    // ptekaApi.get('/search/products', {
    //     params: {
    //         rows: 50,
    //         sort_by: "ctr",
    //         sort: "DESC",
    //         website_id: selected_website
    //     }
    // })
    //     .then(res => {
    //         dispatch({ type: LOADING_CTRS, payload: false })
    //         dispatch({ type: GET_CTRS, payload: res.data.data })
    //     })
    //     .catch(err => {
    //         dispatch({ type: LOADING_CTRS, payload: false })
    //         dispatch(showError(err))
    //     });
};


import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    MultiCascader, Divider, Tag
} from 'rsuite';
import { presistChanges } from '../campaigns-redux';
import { convertToTree, convertCategoriesForFilter, genCatObjectsFromIds, getSelectionValues } from '../../../app/utils';


const CategorySelection = ({ ftype, categories, formData }) => {
    const dispatch = useDispatch();
    const [catsData, setCatsData] = useState([]);
    const [categoriesTree, setCategoriesTree] = useState([]);
    const [selectedValues, setSelectedValues] = useState(getSelectionValues(formData.categories));


    useEffect(() => {
        if (categories && categories.length > 0 && catsData.length == 0 && categoriesTree.length == 0) {
            setCategoriesTree(convertToTree(categories))
            setCatsData(convertCategoriesForFilter(convertToTree(categories)))
        }
    }, [categories]);

    const handleSelectionChange = (values) => {
        setSelectedValues(values);
        dispatch(presistChanges({ ...formData, categories: genCatObjectsFromIds(values, categoriesTree) }, ftype));
    };

    const handleTagClose = (category) => {
        const newCategories = formData.categories.filter((item) => item !== category);
        const newValues = getSelectionValues(newCategories);
        setSelectedValues(newValues);
        dispatch(presistChanges({ ...formData, categories: newCategories }, ftype));
    };

    return (
        <>
            <Divider />
            <h5>Select categories</h5>
            <MultiCascader
                style={{ width: 500 }}
                menuWidth={250}
                menuHeight={300}
                data={catsData}
                valueKey={"id"}
                //inline
                //block
                value={selectedValues}
                onChange={handleSelectionChange}
                preventOverflow={true}
            />

            {formData.categories &&
                formData.categories.length > 0 ?
                <div className='pt-4 pb-2'>Selected categories</div>
                : ""
            }
            {formData.categories.map((category, index) => (
                <Tag
                    className='my-1 mx-2'
                    closable
                    key={index}
                    onClose={() => handleTagClose(category)}
                    color="primary"
                >{category.name}</Tag>
            ))}
        </>

    );
}


export default CategorySelection;
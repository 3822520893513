import { combineReducers } from 'redux';
import { baseReducer } from '../components/_base/base-redux';
import { logsReducer } from '../components/click-logs/click-logs-redux';
import { dashbaordReducer } from '../components/dashboard/dashboard-redux';
import { insightsReducer } from '../components/insights/insights-redux';
import { campaignsReducer } from '../components/campaigns/campaigns-redux';

const rootReducer = combineReducers({
    base: baseReducer,
    logs: logsReducer,
    dashboard: dashbaordReducer,
    insights: insightsReducer,
    campaigns: campaignsReducer
});
export default rootReducer;
//import moment from 'moment-timezone';
import { isEmpty, uniqBy, uniq, cloneDeep, orderBy } from "lodash";


export const hasPermission = (user, allowed_roles = []) => {

    if (user && user.roles && user.roles.length > 0 && allowed_roles && allowed_roles.length > 0)
        for (let role of user.roles) {
            if (role == "ROLE_SUPER_ADMIN")
                return true
            for (let allowed_role of allowed_roles)
                if (role === allowed_role)
                    return true
        }
    return false;
}


export const formatErrors = (obj) => {
    let errors = {};
    Object.keys(obj).forEach(key => {
        if (obj[key].hasError)
            errors[`${key}`] = obj[key].errorMessage
    })
    if (!isEmpty(errors))
        return errors;
    return false;
}

export const formatError = (err) => {
    if (err.response && err.response.data && err.response.data.message)
        return err.response.data.message
    else
        return err.message
}


export const numberFormat = (num, decimal = 0) => {
    if (!num) return 0
    return (
        num
            .toFixed(decimal) // always two decimal digits
            .replace('.', ',') // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    ) // use . as a separator
}

export const convertToTree = (data) => {

    let tree = function (data, root) {
        var r = [], o = {};

        data.forEach(function (a) {
            o[a.id] = { ...a, children: o[a.id] && o[a.id].children };

            if (a.parent === root) {
                r.push(o[a.id]);
            } else {
                o[a.parent.id] = o[a.parent.id] || {};
                o[a.parent.id].children = o[a.parent.id].children || [];
                o[a.parent.id].children.push(o[a.id]);
            }
        });
        return r;
    }(data, null);

    return tree;
}


export const convertCategoriesForFilter = (categories) => {
    let cats = [];
    categories.forEach(function (category) {
        if (category.children) {
            cats.push({
                "id": category.id,
                "slug": category.slug,
                "value": category.id,
                "label": category.name,
                "children": convertCategoriesForFilter(category.children)
            });
        } else {
            cats.push({
                "id": category.id,
                "slug": category.slug,
                "value": category.id,
                "label": category.name
            });
        }

    })
    return cats;
}

export const genCatObjectsFromIds = (ids, category_tree) => {
    let new_arr = [];

    const recurseTree = (node, isParentMatched) => {
        if (ids.includes(node.id) || isParentMatched) {

            new_arr.push({ id: node.id, name: node.name, slug: node.slug }); // add the node to array

            // If this node's ID matches or the parent node's ID was a match, then traverse all children.
            if (node.children) {
                node.children.forEach(child => {
                    recurseTree(child, true);
                });
            }
        } else if (node.children) {
            for (let child of node.children) {
                recurseTree(child, false);
            }
        }
    };

    for (let category of category_tree) {
        recurseTree(category, false);
    }

    return uniqBy(new_arr, 'id');
}

export const genObjectsFromIds = (ids, objects_arr) => {
    let new_arr = [];
    for (const obj of objects_arr) {
        if (ids.includes(obj.id))
            new_arr.push({ id: obj.id, name: obj.name });
    }
    return uniqBy(new_arr, 'id');
}

export const convertBrandsForFilter = (brands) => {
    let brs = [];
    brands.forEach(function (brand) {
        brs.push({ "id": brand.id, "slug": brand.slug, "value": brand.id, "name": brand.name, "label": brand.name });
    })
    return brs;
}

export const convertProductsForFilter = (products) => {
    let prs = [];

    products.forEach(function (product) {
        prs.push({ "id": product.id_numeric, "value": product.id, "label": product.name, "thumb": product.thumb });
    })
    return prs;
}

export const genObjectFromId = (id, arr_objs) => {
    let nobj = null;
    if (arr_objs)
        arr_objs.forEach(obj => {
            if (id && obj.id === id)
                nobj = obj
        })
    return nobj;
}



// export const genCatObjectsFromIds = (ids, arr_objs) => {
//     let new_arr = []
//     if (arr_objs)
//         arr_objs.forEach(obj => {
//             if (ids)
//                 ids.forEach(id => {
//                     if (obj.id === id) {
//                         new_arr.push(obj)
//                         // if (obj.parent)
//                         //     addParents(new_arr, obj.parent)
//                     }
//                 })

//         })
//     //new_arr = 
//     return uniqBy(new_arr, 'id');
// }

export const getSelectionValues = (selected_values) => {
    let new_data = [];
    if (selected_values)
        for (const sl of selected_values)
            new_data.push(sl.id)
    return new_data;
}



// export const genCatObjectsFromIds = (ids, category_tree) => {
//     let new_arr = []


//     if (arr_objs)
//         arr_objs.forEach(obj => {
//             if (ids)
//                 ids.forEach(id => {
//                     if (obj.id === id) {
//                         new_arr.push(obj)
//                         if (obj.children)
//                             addChildrens(new_arr, obj.children)
//                     }
//                 })

//         })
//     //new_arr = 
//     return uniqBy(new_arr, 'id');
// }

// const addChildrens = (arr, childs) => {
//     let c_arr = []
//     if (childs && childs.length > 1) {
//         for (const child of childs) {
//             c_arr.push(child.id)
//             if (child.children)
//                 addChildrens(arr, child.children)
//         }

//     }
//     return arr.push(...c_arr);
// }

export const canManageCampaigns = (user, selected_account) => {
    if (user && user.accounts && user.accounts.length > 0 && selected_account) {
        for (const account of user.accounts) {
            if (account.id == selected_account && account.campaign_management)
                return true

        }
    }
    return false;
}

export const getAccountType = (user, selected_account) => {
    if (user && user.accounts && user.accounts.length > 0 && selected_account) {
        for (const account of user.accounts) {
            if (account.id == selected_account)
                return account.type

        }
    }
    return "";
}

export const getSelectedBrandSlug = (user, selected_account, selected_brand) => {
    if (user && user.accounts && user.accounts.length > 0 && selected_account) {
        for (const account of user.accounts) {
            if (account.id == selected_account && account.brands && account.brands.length > 0) {
                for (const brand of account.brands) {
                    if (brand.id == selected_brand) {
                        return brand.name
                    }
                }
            }
        }
    }
    return null;
}

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    MultiCascader, Divider, Tag
} from 'rsuite';
import { presistChanges } from '../campaigns-redux';
import { convertToTree, convertCategoriesForFilter, genCatObjectsFromIds, getSelectionValues } from '../../../app/utils';


const TagSelection = ({ ftype, formData, user, selected_shop }) => {
    const dispatch = useDispatch();

    const [tags, setTags] = useState([]);
    const [selectedValues, setSelectedValues] = useState(formData.tags);

    //set tags
    useEffect(() => {

        if (user && user.accounts && user.accounts.length > 0) {
            for (const acc of user.accounts) {
                if (acc.shops && acc.shops && acc.shops.length > 0) {
                    for (const shp of acc.shops) {
                        if (shp.id == selected_shop) {
                            setTags([
                                {
                                    label: `Best price`,
                                    value: `top1_${shp.slug}`
                                },
                                {
                                    label: `Price in top 3`,
                                    value: `top3_${shp.slug}`
                                },
                                {
                                    label: `Price in top 5`,
                                    value: `top5_${shp.slug}`
                                },
                                {
                                    label: `Price position 2`,
                                    value: `position2_${shp.slug}`
                                },
                                {
                                    label: `Price position 3`,
                                    value: `position3_${shp.slug}`
                                },
                                {
                                    label: `Price position 4`,
                                    value: `position4_${shp.slug}`
                                },
                                {
                                    label: `Price position 5`,
                                    value: `position5_${shp.slug}`
                                }
                            ])
                        }
                    }
                }
            }
        }


    }, [selected_shop, user])




    const handleSelectionChange = (values) => {
        setSelectedValues(values);
        dispatch(presistChanges({ ...formData, tags: [...values] }, ftype));
    };

    const handleTagClose = (tag) => {
        console.log(tag)
        const newTags = formData.tags.filter((item) => item !== tag);
        setSelectedValues(newTags);
        dispatch(presistChanges({ ...formData, tags: newTags }, ftype));
    };

    return (
        <>
            <MultiCascader
                style={{ width: 500 }}
                menuWidth={250}
                menuHeight={300}
                data={tags}
                //valueKey={"id"}
                //inline
                //block
                placeholder={"Show products based on shop price position"}
                value={selectedValues}
                onChange={handleSelectionChange}
                preventOverflow={true}
            />

            {formData.tags &&
                formData.tags.length > 0 ?
                <div className='pt-4 pb-2'>Selected price position:</div>
                : ""
            }
            {formData.tags.map((tag, index) => (
                <Tag
                    className='my-1 mx-2'
                    closable
                    key={index}
                    onClose={() => handleTagClose(tag)}
                    color="primary"
                >{tag.replace(/_/g, ' ').replace(/\b\w/g, (letter) => letter.toUpperCase())}</Tag>
            ))}
        </>

    );
}


export default TagSelection;













import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from './login-redux';
import { Schema, Container, Content, FlexboxGrid, Panel, Form, ButtonToolbar, Button } from 'rsuite';
import { formatErrors } from '../../app/utils'
import ReCAPTCHA from "react-google-recaptcha";
import styles from './login.module.scss'


const LoginContainer = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [formError, setFormError] = useState({});
    const [credentials, setCredentials] = useState({ username: "", password: "" });
    const { StringType } = Schema.Types;
    const model = Schema.Model({
        username: StringType().isEmail('Please insert valid email.').isRequired('Required field.'),
        password: StringType().isRequired('Required field.')
    });
    const base = useSelector(state => state.base);
    const handleChange = useCallback(
        form => {
            setFormError({})
            setCredentials({
                username: form.username ? form.username : "",
                password: form.password ? form.password : ""
            })
        }, [setCredentials, setFormError]
    )
    const handleFormSubmit = useCallback(
        async (recaptchaRef) => {
            const err = formatErrors(model.check(credentials));
            if (err) {
                setFormError(err)
                return;
            }
            const token = await recaptchaRef.current.executeAsync();
            dispatch(loginUser(credentials, history, token))
        }, [dispatch, credentials, history, model, setFormError]
    )
    const recaptchaRef = React.createRef();
    return (
        <Container className={styles.login_bg}>
            <Content>
                <FlexboxGrid justify="center">
                    <FlexboxGrid.Item className={styles.login_box}>
                        <div className={styles.logo_area}>
                            <img src={`${process.env.REACT_APP_LOGO}`} alt="logo" />
                        </div>
                        <Panel className={"bg-white"} header={<h5 className="primary-color">{"Login " + process.env.REACT_APP_WEBSITE_NAME}</h5>} bordered shaded>
                            <Form
                                fluid
                                onChange={(e) => handleChange(e)}
                                model={model}
                                checkTrigger={"blur"}
                                formError={formError}
                                formValue={credentials}
                            >
                                <Form.Group>
                                    <Form.Control name="username" type="email" placeholder="Email" value={credentials.username} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Control name="password" type="password" placeholder="Password" value={credentials.password} />
                                </Form.Group>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    size="invisible"
                                    sitekey="6LfVCKwaAAAAABVd0ATUFxK78QzLRDmxQyWcXrIE"
                                />
                                <Form.Group>
                                    <ButtonToolbar>
                                        <Button appearance="primary" type="submit" onClick={() => handleFormSubmit(recaptchaRef)} loading={base.loading}>Login</Button>
                                        <Button appearance="link" onClick={() => history.push("/forgot")}>Forgot password?</Button>
                                    </ButtonToolbar>
                                </Form.Group>
                            </Form>
                        </Panel>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
        </Container>
    )
}

export default LoginContainer;
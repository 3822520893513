import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAll } from './click-logs-redux'

import { Content, Panel, Table, Pagination } from 'rsuite';
import moment from 'moment-timezone';
import { numberFormat, canManageCampaigns } from '../../app/utils';

const { Column, HeaderCell, Cell } = Table;

const ClickLogs = () => {
    const selected_shop = useSelector(state => state.base.selected_shop);
    const selected_account = useSelector(state => state.base.selected_account);
    const selected_brand = useSelector(state => state.base.selected_brand);
    const selected_website = useSelector(state => state.base.selected_website);
    const user = useSelector(state => state.base.user);


    const [data, setData] = useState([]);
    const logs = useSelector(state => state.logs);

    const dispatch = useDispatch();
    useEffect(() => {
        if (selected_account)
            dispatch(getAll());
    }, [dispatch, selected_shop, selected_account, selected_brand, selected_website]);


    useEffect(() => {
        let d = [];
        if (logs && logs.data && logs.data.length > 0)
            for (const log of logs.data) {
                d.push({
                    created_at: moment(log.created_at).format("DD.MM.YYYY HH:mm:ss"),
                    ip_address: log.ip_address ? log.ip_address : "",
                    shop: log.shop && log.shop.name ? log.shop.name : "",
                    category: log.redirect_category && log.redirect_category.name ? log.redirect_category.name : "",
                    product: log.product_variation && log.product_variation.name ? log.product_variation.name : "",
                    product_link: log.product_variation && log.product_variation.slug && log.website && log.website.domain ? log.website.domain + "/p/" + log.product_variation.slug + "/" : "#",
                    link: log.redirect_url ? log.redirect_url : "",
                    user_agent: log.user_agent ? log.user_agent : "",
                    product_price: log.product_price ? log.product_price : null,
                    click_price: log.click_price ? log.click_price : null,
                    bid_price: log.bid_price ? log.bid_price : null,
                })
            }
        setData(d)
    }, [logs]);

    const handlePageChange = (sp) => {
        dispatch(getAll({ ...logs.page, number: sp - 1 }))
    }

    const LinkCell = ({ rowData, dataKey, ...props }) => {
        return (
            <Cell {...props}>
                {rowData.link ? <a href={rowData.link} target="_blank">{rowData.link}</a> : "-"}
            </Cell>
        );
    }

    const NumberCell = ({ rowData, dataKey, ...props }) => {
        return (
            <Cell {...props}>

                {rowData[dataKey] != "-" ? <>
                    {numberFormat(rowData[dataKey], 2)}
                </> : ""}


            </Cell >
        );
    }

    return (
        <>
            <Content>
                <Panel header={"Click Logs"} className={"wrap-panel"}>

                    <Panel shaded className='mt-3'>
                        <Table
                            autoHeight
                            data={data}
                            // onRowClick={rowData => {
                            //     //console.log(rowData);
                            // }}
                            wordWrap="keep-all"
                            loading={logs.page.loading}
                        >
                            <Column width={160} verticalAlign='middle'>
                                <HeaderCell>Date</HeaderCell>
                                <Cell dataKey="created_at" />
                            </Column>

                            <Column width={150} verticalAlign='middle'>
                                <HeaderCell>Payment Category</HeaderCell>
                                <Cell dataKey="category" />
                            </Column>
                            <Column width={250} verticalAlign='middle'>
                                <HeaderCell>Product</HeaderCell>

                                <Cell>
                                    {rowData => (
                                        <>
                                            {
                                                rowData.product_link && rowData.product ?
                                                    <a target="_blank" rel="noreferrer" href={rowData.product_link}>{rowData.product}</a>
                                                    : rowData.product
                                            }
                                        </>
                                    )}
                                </Cell>
                            </Column>
                            <Column width={100} verticalAlign='middle' align='center'>
                                <HeaderCell>Product Price</HeaderCell>
                                <NumberCell dataKey="product_price" />
                            </Column>
                            <Column width={100} verticalAlign='middle' align='center'>
                                <HeaderCell>Click Price</HeaderCell>
                                <NumberCell dataKey="click_price" />
                            </Column>
                            {canManageCampaigns(user, selected_account) ?
                                <Column width={100} verticalAlign='middle' align='center'>
                                    <HeaderCell>Bid Price</HeaderCell>
                                    <NumberCell dataKey="bid_price" />
                                </Column>
                                : ""}

                            <Column width={140} verticalAlign='middle' align='center'>
                                <HeaderCell>IP Address</HeaderCell>
                                <Cell dataKey="ip_address" />
                            </Column>
                            <Column width={250} verticalAlign='middle'>
                                <HeaderCell>Browser (User Agent)</HeaderCell>
                                <Cell dataKey="user_agent" />
                            </Column>
                            <Column width={150} verticalAlign='middle' align='center'>
                                <HeaderCell>Shop</HeaderCell>
                                <Cell dataKey="shop" />
                            </Column>
                            <Column width={300} verticalAlign='middle'>
                                <HeaderCell>Destination Link</HeaderCell>
                                <LinkCell dataKey="link" />
                            </Column>

                        </Table>
                        <div className='py-5'>
                            <Pagination
                                prev
                                next
                                first
                                last
                                ellipsis
                                boundaryLinks
                                maxButtons={5}
                                size="md"
                                layout={['total', '-', '|', 'pager']}
                                activePage={parseInt(logs.page.number) + 1}
                                total={parseInt(logs.page.total_found)}
                                limit={parseInt(logs.page.size)}
                                onChangePage={handlePageChange}
                            />
                        </div>
                    </Panel>

                </Panel>
            </Content>
        </>
    )
}

export default ClickLogs;
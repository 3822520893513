import React, { useState, useCallback } from 'react';


const ResetPasswordContainer = ({ match }) => {
    /*const dispatch = useDispatch();
    const history = useHistory();
    const [formError, setFormError] = useState({});
    const [credentials, setCredentials] = useState({ new_password: "", repeat_password: "", reset_token: match.params.token });

    const { StringType } = Schema.Types;

    const model = Schema.Model({
        new_password: StringType().pattern(
            /(?=.*[A-Z])(?=.*[0-9])/,
            'Min 8 characters, at least one capital letter and one number'
        ).isRequired('This field is required.'),
        repeat_password: StringType().addRule((value, data) => {
            if (value !== data.new_password) {
                return false;
            }
            return true;
        }, 'Passwords doesn`t match.')

    });

    const handleChange = useCallback(
        form => {
            setFormError({})
            setCredentials({
                new_password: form.new_password ? form.new_password : "",
                repeat_password: form.repeat_password ? form.repeat_password : "",
                reset_token: credentials.reset_token
            })
        }, [setCredentials, setFormError, credentials]
    )

    const handleFormSubmit = useCallback(
        () => {
            const err = formatErrors(model.check(credentials));
            if (err) {
                setFormError(err)
                return;
            }
            dispatch(resetPass(credentials, history))
        }, [dispatch, credentials, history, model, setFormError]
    )

    return (
        <ResetPassword
            handleChange={handleChange}
            handleFormSubmit={handleFormSubmit}
            credentials={credentials}
            model={model}
            formError={formError}
            history={history}
        />
    )*/
    return (<div>{"Reset screen"}</div>)
}

export default ResetPasswordContainer;
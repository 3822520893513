import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    AutoComplete, Divider, FlexboxGrid, Drawer, InputGroup, Input, Button, Table, IconButton
} from 'rsuite';
import { presistChanges, getProductsSelection, getProductsSelectionByBarcodes } from '../campaigns-redux';
import { convertProductsForFilter, getSelectionValues, genObjectFromId, getSelectedBrandSlug } from '../../../app/utils';
import SearchIcon from '@rsuite/icons/Search';
import PlusIcon from '@rsuite/icons/Plus';
import FileUploadIcon from '@rsuite/icons/FileUpload';
import CloseIcon from '@rsuite/icons/Close';

const { Column, HeaderCell, Cell } = Table;

const ProductSelection = ({ ftype, products, formData, user, selected_shop, selected_brand, selected_account, selected_categories = [], limit }) => {
    const dispatch = useDispatch();
    const max_products = limit ? limit : 100;
    const [search, setSearch] = useState("");
    const [shopSlug, setShopSlug] = useState("");


    const [openBarcodesImport, setOpenBarcodesImport] = useState(false);
    const [barcodesList, setBarcodesList] = useState("");
    const [barcodesArray, setBarcodesArray] = useState([]);


    const handleAutoCompleteChange = (s) => {
        if (s != search && !s.includes("variation_")) {
            setSearch(s)
            dispatch(getProductsSelection(search, shopSlug, selected_categories, getSelectedBrandSlug(user, selected_account, selected_brand)));
        }
    };
    useEffect(() => {
        if (selected_shop && user && user.accounts && user.accounts.length > 0) {
            for (const account of user.accounts)
                if (account.shops && account.shops.length > 0)
                    for (const shop of account.shops)
                        if (shop.id == parseInt(selected_shop))
                            setShopSlug(shop.slug)
        }
    }, [selected_shop, user.shops]);

    const handleSelectionChange = (value) => {
        setSearch("")
        dispatch(presistChanges({
            ...formData,
            product_variations: [...formData.product_variations, genObjectFromId(value, products)]
        }, ftype));
    };

    const handleTagClose = (product) => {
        const newProducts = formData.product_variations.filter((item) => item !== product);
        const newValues = getSelectionValues(newProducts);
        dispatch(presistChanges({ ...formData, product_variations: newProducts }, ftype));
    };


    const handleImportBarcodes = () => {
        setOpenBarcodesImport(false)
        dispatch(getProductsSelectionByBarcodes(barcodesArray, shopSlug, ftype, getSelectedBrandSlug(user, selected_account, selected_brand)));
    };


    const handleTextAreaChange = (text) => {

        const barcodesListArray = text.split(/\r?\n/);
        // Limit to 200 lines
        if (barcodesListArray.length > max_products) {
            const truncatedText = barcodesListArray.slice(0, max_products).join('\n');
            setBarcodesList(truncatedText);
            setBarcodesArray(truncatedText.split(/\r?\n/))
        } else {
            setBarcodesList(text);
            setBarcodesArray(barcodesListArray)
        }
    };


    const handleClearAll = () => {
        dispatch(presistChanges({ ...formData, product_variations: [] }, ftype));
    };


    return (
        <>
            <Divider />


            <FlexboxGrid className={"mt-4 pb-2"}>
                <FlexboxGrid.Item className={"pr-2"} style={{ width: 300 }}>
                    <h5>Select products</h5>
                    <div className='pb-4'>{"Search by product name or barcode or click on the icon and upload list of barcodes."}</div>
                    {limit ?
                        <div className='pb-4'>{`You can select up to ${limit} products`}</div>
                        : ""}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className={"pr-2 text-right"} style={{ width: 200 }}>
                    {formData.product_variations && formData.product_variations.length > 0 ?
                        <Button onClick={handleClearAll}>
                            {"Clear all"}
                        </Button>
                        : ""}
                </FlexboxGrid.Item>
            </FlexboxGrid>



            {(!limit || (limit && formData.product_variations && formData.product_variations.length < limit)) ?
                <InputGroup className='mb-2'>
                    <AutoComplete
                        data={convertProductsForFilter(products)}
                        style={{ width: 500 }}
                        onChange={handleAutoCompleteChange}
                        onSelect={handleSelectionChange}
                        //onChange={handleSelectionChange}
                        value={search}
                        placeholder='Search by product name or upload list of barcodes....'
                        renderMenuItem={(label, item) => {
                            return (
                                <div>
                                    {item.thumb ?
                                        <img src={item.thumb} alt="thumb" className='thumb-autocomplete ' />
                                        : ""}
                                    <span className='mr-3'> {label}</span>
                                    <PlusIcon />
                                </div>
                            );
                        }}
                    />
                    <InputGroup.Button tabIndex={-2} onClick={() => setOpenBarcodesImport(true)}>
                        Upload <FileUploadIcon className='ml-2' />
                    </InputGroup.Button>

                </InputGroup>
                : ""}

            <Drawer open={openBarcodesImport} onClose={() => setOpenBarcodesImport(false)}>
                <Drawer.Header>
                    <Drawer.Title>Multi products selection - EAN / Barcodes</Drawer.Title>
                    <Drawer.Actions>
                        <Button onClick={() => setOpenBarcodesImport(false)}>Cancel</Button>
                        <Button onClick={handleImportBarcodes} appearance="primary">
                            Confirm
                        </Button>
                    </Drawer.Actions>
                </Drawer.Header>
                <Drawer.Body>
                    <div>Total barcodes: {barcodesArray.length} / {max_products}</div>
                    <Input
                        value={barcodesList}
                        onChange={handleTextAreaChange}
                        as="textarea"
                        rows={20}
                        placeholder={"Paste list of barcodes here, each barcode new line. Maximum number of barcodes you can paste is " + max_products + ". If you need more barcodes, please repeat process."}
                    />
                </Drawer.Body>
            </Drawer>


            <Table className="pt-4" virtualized height={400} width={500} data={formData.product_variations}>

                <Column width={440}>
                    <HeaderCell>{"Selected products"}</HeaderCell>
                    <NameCell dataKey="name" />
                </Column>

                <Column width={60} fixed="right" className={"text-right"}>
                    <HeaderCell></HeaderCell>
                    <Cell style={{ paddingTop: '10px', paddingRight: '20px' }}>
                        {rowData => (
                            <IconButton
                                circle
                                size="xs"
                                appearance="subtle"
                                onClick={() => handleTagClose(rowData)}
                                icon={<CloseIcon />}
                                className="ml-1"
                            />
                        )}
                    </Cell>
                </Column>
            </Table>








        </>

    );
}


const NameCell = ({ rowData, dataKey, handleEditAction, imageKey, ...props }) => {
    return (
        <Cell {...props}>
            {
                <>
                    {rowData.thumb ?
                        <div className="small-thumb">
                            <img src={rowData.thumb} className="img-thumbnail" alt={"pimage"} />
                        </div>
                        : ""}
                    <div className="title-with-thumb">
                        {rowData[`${dataKey}`]}
                    </div>
                </>
            }
        </Cell >
    );
}


export default ProductSelection;
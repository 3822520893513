import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getImpressionsAndClicks, getImpressionsAndClicksCampaigns, getCategoryClicks } from './dashboard-redux'


import { Content, Panel, FlexboxGrid, Loader } from 'rsuite';
import DatePicker from '../_common/date-picker'
import ClickChart from './click-chart';
import CategoryClicks from './category-clicks'
import CategoryClicksCampaigns from './category-clicks-campaigns'
import moment from 'moment-timezone';
import { canManageCampaigns, getAccountType } from '../../app/utils';

const Dashboard = () => {

    const selected_shop = useSelector(state => state.base.selected_shop);
    const selected_account = useSelector(state => state.base.selected_account);
    const selected_brand = useSelector(state => state.base.selected_brand);
    const selected_website = useSelector(state => state.base.selected_website);
    const user = useSelector(state => state.base.user);
    const categories_clicks = useSelector(state => state.dashboard.category_clicks);

    const impressions_clicks = useSelector(state => state.dashboard.impression_clicks);
    const impressions_clicks_campaigns = useSelector(state => state.dashboard.impression_clicks_campaigns);
    const currency = useSelector(state => state.base.website.currency);


    const [date, setDate] = useState({
        start: moment().startOf("month"),
        end: moment().endOf("month")
    })

    const dispatch = useDispatch();

    useEffect(() => {
        if (selected_account && date.start && date.end) {
            dispatch(getImpressionsAndClicks(date.start.format('YYYY-MM-DD HH:mm:ss'), date.end.format('YYYY-MM-DD HH:mm:ss')));
            if (getAccountType(user, selected_account) != "brand")
                dispatch(getCategoryClicks(
                    date.start.format('YYYY-MM-DD HH:mm:ss'),
                    date.end.format('YYYY-MM-DD HH:mm:ss'),
                ));
        }
    }, [dispatch, selected_shop, selected_account, selected_brand, selected_website, date]);


    useEffect(() => {
        if (selected_account && date.start && date.end && user && canManageCampaigns(user, selected_account)) {
            dispatch(getImpressionsAndClicksCampaigns(date.start.format('YYYY-MM-DD HH:mm:ss'), date.end.format('YYYY-MM-DD HH:mm:ss')));
        }
    }, [dispatch, selected_shop, selected_account, selected_brand, selected_website, date, user]);

    return (
        <>
            <Content>

                <FlexboxGrid className='justify-content-between'>
                    <FlexboxGrid.Item >
                        <DatePicker
                            className="ml-3"
                            placeholder="Select date range"
                            onChange={(value) => {
                                setDate({
                                    start: moment(value[0]).startOf("day"),
                                    end: moment(value[1]).endOf("day")
                                })
                            }}
                            value={[
                                new Date(date.start.format("YYYY-MM-DD")),
                                new Date(date.end.format("YYYY-MM-DD"))
                            ]}
                        />
                    </FlexboxGrid.Item>
                    {canManageCampaigns(user, selected_account) && getAccountType(user, selected_account) != "brand" ?
                        <FlexboxGrid.Item>
                            <Panel header={<>{"TOTAL COSTS"}<br />{"(Regular + Campaigns)"}</>} shaded className="big-counter-total">
                                {!impressions_clicks_campaigns.loading && !impressions_clicks.loading ? <>
                                    {isNaN(Math.round((impressions_clicks_campaigns.cpc.total_campaign_price + impressions_clicks_campaigns.cpc.total_price + impressions_clicks_campaigns.cpm.total_campaign_price + impressions_clicks.total_price) * 100) / 100) ?
                                        "0"
                                        :
                                        Math.round((impressions_clicks_campaigns.cpc.total_campaign_price + impressions_clicks_campaigns.cpm.total_campaign_price + impressions_clicks_campaigns.cpc.total_price + impressions_clicks.total_price) * 100) / 100}<span className='currency'>{currency}</span>
                                </> : <Loader />}
                            </Panel>
                        </FlexboxGrid.Item>
                        : ""}
                </FlexboxGrid>

                {getAccountType(user, selected_account) != "brand" ?
                    <FlexboxGrid>
                        <FlexboxGrid.Item className={"w-50"}>
                            <Panel header={canManageCampaigns(user, selected_account) ? "Regular Clicks" : "Impressions and Clicks"} className={"wrap-panel"}>
                                <FlexboxGrid className='pt-4'>
                                    <FlexboxGrid.Item className={"px-1 pb-2"}>
                                        <Panel header={"Impressions"} shaded className="big-counter">
                                            {!impressions_clicks.loading ? <>
                                                {impressions_clicks.impressions}
                                            </> : <Loader />}
                                        </Panel>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item className={"px-1 pb-2"}>
                                        <Panel header={"Clicks"} shaded className="big-counter">
                                            {!impressions_clicks.loading ? <>
                                                {impressions_clicks.total_clicks}
                                            </> : <Loader />}
                                        </Panel>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item className={"px-1 pb-2"}>
                                        <Panel header={"CTR"} shaded className="big-counter">
                                            {!impressions_clicks.loading ? <>
                                                {
                                                    isNaN(Math.round((impressions_clicks.total_clicks / impressions_clicks.impressions * 100) * 100) / 100) ?
                                                        "0" :
                                                        Math.round((impressions_clicks.total_clicks / impressions_clicks.impressions * 100) * 100) / 100

                                                }<small>%</small>
                                            </> : <Loader />}
                                        </Panel>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item className={"px-1 pb-2"}>
                                        <Panel header={"Costs"} shaded className="big-counter">
                                            {!impressions_clicks.loading ? <>
                                                {Math.round(impressions_clicks.total_price * 100) / 100}<span className='currency'>{currency}</span>
                                            </> : <Loader />}
                                        </Panel>
                                    </FlexboxGrid.Item>

                                    <FlexboxGrid.Item className={"w-100 pt-2"}>
                                        <Panel shaded>
                                            <div style={{ width: "100%", height: 230 }}>
                                                {!impressions_clicks.loading ?
                                                    <ClickChart
                                                        clicks={impressions_clicks.clicks}
                                                        start={date.start}
                                                        end={date.end}
                                                        currency={currency}
                                                    />
                                                    : <Loader />}
                                            </div>
                                        </Panel>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </Panel>
                        </FlexboxGrid.Item>


                        <FlexboxGrid.Item className={"w-50"}>
                            <Panel header={"Categories"} className={"wrap-panel"}>
                                <CategoryClicks
                                    clicks={categories_clicks}
                                />
                            </Panel>
                        </FlexboxGrid.Item>

                    </FlexboxGrid>
                    :
                    ""
                }

                {canManageCampaigns(user, selected_account) && getAccountType(user, selected_account) != "brand" ?
                    <FlexboxGrid>
                        <FlexboxGrid.Item className={"w-50"}>
                            <Panel header={"PPC - Campaigns"} className={"wrap-panel"}>
                                <FlexboxGrid className='pt-4'>
                                    <FlexboxGrid.Item className={"px-1 pb-2"}>
                                        <Panel header={"Impressions"} shaded className="big-counter">
                                            {!impressions_clicks_campaigns.loading ? <>
                                                {impressions_clicks_campaigns.cpc.impressions}
                                            </> : <Loader />}
                                        </Panel>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item className={"px-1 pb-2"}>
                                        <Panel header={"Clicks"} shaded className="big-counter">
                                            {!impressions_clicks_campaigns.loading ? <>
                                                {impressions_clicks_campaigns.cpc.total_clicks}
                                            </> : <Loader />}
                                        </Panel>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item className={"px-1 pb-2"}>
                                        <Panel header={"CTR"} shaded className="big-counter">
                                            {!impressions_clicks_campaigns.loading ? <>
                                                {
                                                    isNaN(Math.round((impressions_clicks_campaigns.cpc.total_clicks / impressions_clicks_campaigns.cpc.impressions * 100) * 100) / 100) ?
                                                        "0" :
                                                        Math.round((impressions_clicks_campaigns.cpc.total_clicks / impressions_clicks_campaigns.cpc.impressions * 100) * 100) / 100

                                                }<small>%</small>
                                            </> : <Loader />}
                                        </Panel>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item className={"px-1 pb-2"}>
                                        <Panel header={"Campaign Bidding Costs"} shaded className="big-counter">
                                            {!impressions_clicks_campaigns.loading ? <>
                                                {isNaN(Math.round(impressions_clicks_campaigns.cpc.total_campaign_price * 100) / 100) ? "0" : Math.round(impressions_clicks_campaigns.cpc.total_campaign_price * 100) / 100}<span className='currency'>{currency}</span>
                                            </> : <Loader />}
                                        </Panel>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item className={"px-1 pb-2"}>
                                        <Panel header={"Click Base Costs"} shaded className="big-counter">
                                            {!impressions_clicks_campaigns.loading ? <>
                                                {isNaN(Math.round(impressions_clicks_campaigns.cpc.total_price * 100) / 100) ? "0" : Math.round(impressions_clicks_campaigns.cpc.total_price * 100) / 100}<span className='currency'>{currency}</span>
                                            </> : <Loader />}
                                        </Panel>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item className={"px-1 pb-2"}>
                                        <Panel header={"Total Campaign Costs"} shaded className="big-counter">
                                            {!impressions_clicks_campaigns.loading ? <>
                                                {isNaN(Math.round((impressions_clicks_campaigns.cpc.total_campaign_price + impressions_clicks_campaigns.cpc.total_price) * 100) / 100) ? "0" : Math.round((impressions_clicks_campaigns.cpc.total_campaign_price + impressions_clicks_campaigns.cpc.total_price) * 100) / 100}<span className='currency'>{currency}</span>
                                            </> : <Loader />}
                                        </Panel>
                                    </FlexboxGrid.Item>

                                    <FlexboxGrid.Item className={"w-100 pt-2"}>
                                        <Panel shaded>
                                            <div style={{ width: "100%", height: 230 }}>
                                                {!impressions_clicks_campaigns.loading ?
                                                    <ClickChart
                                                        clicks={impressions_clicks_campaigns.cpc.clicks}
                                                        start={date.start}
                                                        end={date.end}
                                                        currency={currency}
                                                    />
                                                    : <Loader />}
                                            </div>
                                        </Panel>
                                    </FlexboxGrid.Item>

                                </FlexboxGrid>
                            </Panel>
                        </FlexboxGrid.Item>


                        <FlexboxGrid.Item className={"w-50"}>
                            <Panel header={""} className={"wrap-panel"}>
                                <CategoryClicksCampaigns
                                    clicks={categories_clicks}
                                />
                            </Panel>
                        </FlexboxGrid.Item>


                    </FlexboxGrid>

                    : ""}

                {canManageCampaigns(user, selected_account) ?
                    <FlexboxGrid>
                        <FlexboxGrid.Item className={"w-50 pt-5"}>
                            <Panel header={"CPM - Campaigns"} className={"wrap-panel"}>
                                <FlexboxGrid className='pt-2'>
                                    <FlexboxGrid.Item className={"px-1 pb-2"}>
                                        <Panel header={"Impressions"} shaded className="big-counter">
                                            {!impressions_clicks_campaigns.loading ? <>
                                                {impressions_clicks_campaigns.cpm.impressions}
                                            </> : <Loader />}
                                        </Panel>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item className={"px-1 pb-2"}>
                                        <Panel header={"Clicks"} shaded className="big-counter">
                                            {!impressions_clicks_campaigns.loading ? <>
                                                {impressions_clicks_campaigns.cpm.total_clicks}
                                            </> : <Loader />}
                                        </Panel>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item className={"px-1 pb-2"}>
                                        <Panel header={"CTR"} shaded className="big-counter">
                                            {!impressions_clicks_campaigns.loading ? <>
                                                {
                                                    isNaN(Math.round((impressions_clicks_campaigns.cpm.total_clicks / impressions_clicks_campaigns.cpm.impressions * 100) * 100) / 100) ?
                                                        "0" :
                                                        Math.round((impressions_clicks_campaigns.cpm.total_clicks / impressions_clicks_campaigns.cpm.impressions * 100) * 100) / 100

                                                }<small>%</small>
                                            </> : <Loader />}
                                        </Panel>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item className={"px-1 pb-2"}>
                                        <Panel header={"Costs"} shaded className="big-counter">
                                            {!impressions_clicks_campaigns.loading ? <>
                                                {isNaN(Math.round((impressions_clicks_campaigns.cpm.total_campaign_price) * 100) / 100) ? "0" : Math.round((impressions_clicks_campaigns.cpm.total_campaign_price) * 100) / 100}<span className='currency'>{currency}</span>
                                            </> : <Loader />}
                                        </Panel>
                                    </FlexboxGrid.Item>

                                    <FlexboxGrid.Item className={"w-100 pt-2"}>
                                        <Panel shaded>
                                            <div style={{ width: "100%", height: 230 }}>
                                                {!impressions_clicks_campaigns.loading ?
                                                    <ClickChart
                                                        clicks={impressions_clicks_campaigns.cpm.clicks}
                                                        start={date.start}
                                                        end={date.end}
                                                        currency={currency}
                                                    />
                                                    : <Loader />}
                                            </div>
                                        </Panel>
                                    </FlexboxGrid.Item>

                                </FlexboxGrid>
                            </Panel>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    : ""}




            </Content>
        </>
    )
}

export default Dashboard;
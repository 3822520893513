
import {
    Form, InputNumber, DatePicker
} from 'rsuite';
import moment from 'moment-timezone';

export const NumberField = ({ name, label, help, ...rest }) => (
    <Form.Group controlId={name}>
        <Form.ControlLabel>{label} </Form.ControlLabel>
        {help ?
            <Form.HelpText tooltip className='pr-3'>{help}</Form.HelpText>
            : ""}
        <Form.Control name={name} accepter={InputNumber} scrollable={false} {...rest}/>
    </Form.Group>
);

export const DateField = ({ name, label, ...rest }) => (
    <Form.Group controlId={name}>
        <Form.ControlLabel>{label}</Form.ControlLabel>
        <Form.Control name={name} accepter={DatePicker} {...rest} />
    </Form.Group>
);

export const StartDateField = ({ name, label, value }) => {

    // console.log(value)
    // return <></>
    return <DateField
        name={name}
        placeholder="Select"
        label={label}
        style={{ width: 320 }}
        format={"dd.MM.yyyy HH:mm"}
        isoWeek={true}
        oneTap
        value={value ? new Date(value) : null}
        ranges={[
            {
                label: 'Now',
                value: new Date(),
                placement: 'left'
            },
            {
                label: 'Tomorrow',
                value: moment().add(1, 'days').startOf('day').toDate(),
                placement: 'left'
            }
        ]}
    />
};

export const EndDateField = ({ name, label, value }) => {

    return <DateField
        name={name}
        placeholder="Select"
        label={label}
        style={{ width: 320 }}
        format={"dd.MM.yyyy HH:mm"}
        isoWeek={true}
        oneTap
        value={value ? new Date(value) : null}
        ranges={[
            {
                label: '7 days',
                value: moment().add(7, 'days').endOf('day').toDate(),
                placement: 'left'
            },
            {
                label: '14 days',
                value: moment().add(14, 'days').endOf('day').toDate(),
                placement: 'left'
            },
            {
                label: '30 days',
                value: moment().add(30, 'days').endOf('day').toDate(),
                placement: 'left'
            },
            {
                label: '90 days',
                value: moment().add(90, 'days').endOf('day').toDate(),
                placement: 'left'
            },
            {
                label: 'End of month',
                value: moment().endOf('month').toDate(),
                placement: 'left'
            }
        ]}
    />
};
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Content, Panel, FlexboxGrid, Table, Button } from 'rsuite';
import { getCampaigns } from './campaigns-redux';
import { numberFormat } from '../../app/utils';

const { Column, HeaderCell, Cell } = Table;



const CampaignsReporting = () => {


    const campaigns = useSelector(state => state.campaigns.data);


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCampaigns());
    }, [dispatch]);




    return (
        <>
            <Content>
                <Panel header={"Campaign reporting"} className={"wrap-panel"}>
                <p>
                        {"In development..."}
                    </p>
                  
                  
                </Panel>



            </Content>
        </>
    )
}

export default CampaignsReporting;
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Content, Panel, FlexboxGrid, Table } from 'rsuite';
import { getLikes, getViews, getCtrs } from './insights-redux';
import { numberFormat } from '../../app/utils';

const { Column, HeaderCell, Cell } = Table;



const Insights = () => {

    const [likesData, setLikesData] = useState([]);
    const [viewsData, setViewsData] = useState([]);
    const [ctrsData, setCtrsData] = useState([]);
    const selected_website = useSelector(state => state.base.selected_website);

    const views = useSelector(state => state.insights.views);
    const ctrs = useSelector(state => state.insights.ctrs);

    const dispatch = useDispatch();
    useEffect(() => {
        if (selected_website) {
            dispatch(getViews());
            dispatch(getCtrs());
        }
    }, [dispatch, selected_website]);




    useEffect(() => {
        let data = [];
        if (views.data && views.data.length > 0) {
            let i = 1;
            for (let product of views.data) {
                data.push({
                    id: i,
                    name: product.name,
                    views: product.views,
                })
                i++
            }
        }
        setViewsData(data)
    }, [views]);

    useEffect(() => {
        let data = [];
        if (ctrs.data && ctrs.data.length > 0) {
            let i = 1;
            for (let product of ctrs.data) {
                data.push({
                    id: i,
                    name: product.name,
                    ctr: product.ctr,
                })
                i++
            }
        }
        setCtrsData(data)
    }, [ctrs]);



    const CTRCell = ({ rowData, dataKey, ...props }) => {
        return (
            <Cell {...props}>
                {
                    <>
                        {Math.round((rowData.ctr * 100)) / 100}%
                    </>
                }
            </Cell >
        );
    }

    const LikesCell = ({ rowData, dataKey, ...props }) => {
        return (
            <Cell {...props}>
                {numberFormat(rowData.likes)}
            </Cell >
        );
    }

    const ViewsCell = ({ rowData, dataKey, ...props }) => {
        return (
            <Cell {...props}>
                {numberFormat(rowData.views)}
            </Cell >
        );
    }

    return (
        <>
            <Content>
                <Panel header={"Insights"} className={"wrap-panel"}>

                    <FlexboxGrid>

                        <FlexboxGrid.Item className='w-50'>
                            <Panel header={"Most viewed products"} shaded className='mt-3'>
                                <div style={{ marginTop: -20 }} className={"pb-3"}>{"(last 3 months)"}</div>
                                <Table
                                    autoHeight
                                    data={viewsData}
                                    wordWrap
                                    loding={views.loading}
                                >
                                    <Column width={40}>
                                        <HeaderCell></HeaderCell>
                                        <Cell dataKey="id" />
                                    </Column>
                                    <Column width={450}>
                                        <HeaderCell>Product</HeaderCell>
                                        <Cell dataKey="name" />
                                    </Column>
                                    {/* <Column width={100}>
                                        <HeaderCell>Views</HeaderCell>
                                        <ViewsCell dataKey="views" />
                                    </Column> */}
                                </Table>

                            </Panel>
                        </FlexboxGrid.Item>

                        {/* <FlexboxGrid.Item className='w-50 pl-2'>
                            <Panel header={"Products with most redirects"} shaded className='mt-3'>
                                <div style={{ marginTop: -20 }} className={"pb-3"}>{"(last 3 months)"}</div>
                                <Table
                                    autoHeight
                                    data={ctrsData}
                                    wordWrap
                                    loding={ctrs.loading}
                                >
                                    <Column width={40}>
                                        <HeaderCell></HeaderCell>
                                        <Cell dataKey="id" />
                                    </Column>
                                    <Column width={450}>
                                        <HeaderCell>Product</HeaderCell>
                                        <Cell dataKey="name" />
                                    </Column>
                                    <Column width={100}>
                                        <HeaderCell>CTR %</HeaderCell>
                                        <CTRCell dataKey="ctr" />
                                    </Column>
                                </Table>

                            </Panel>
                        </FlexboxGrid.Item> */}
                    

                    </FlexboxGrid>
                </Panel>
            </Content>
        </>
    )
}

export default Insights;